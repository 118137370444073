import classNames from "classnames";
import React, { FC } from "react";
import Text from "src/components/atoms/Text";

interface RangePropsDataType {
  sections: number;
  labels: string[];
  position: number;
  markers: number[];
  value: string;
  type: string;
}

interface RangePropsType {
  data: RangePropsDataType;
}

const Range: FC<RangePropsType> = ({ data }) => {
  const { sections, labels, position, markers, value } = data;
  const renderLines = (section: number, lineColorClass: string) => {
    let shortLines = Array.from(
      { length: section === 2 ? 13 : 8 },
      (_, i) => i
    );

    return (
      <>
        <div
          className={classNames(
            "flex justify-center",
            section !== 4 ? "w-full items-center" : "items-end"
          )}
        >
          <div className={classNames("flex  items-end gap-1")}>
            {section === 4 && (
              <>
                <div className={classNames("h-1 w-0.5 ", lineColorClass)}></div>
                <div className={classNames("h-1 w-0.5 ", lineColorClass)}></div>
                <div className={classNames("h-1 w-0.5 ", lineColorClass)}></div>
              </>
            )}
            {Array.from(
              { length: section !== 4 ? section : section - 1 },
              (_, i) => i
            ).map((sec) => (
              <>
                <div className={classNames("h-4 w-0.5", lineColorClass)}></div>
                {shortLines.map((line) => (
                  <div
                    className={classNames("h-1 w-0.5 ", lineColorClass)}
                  ></div>
                ))}
              </>
            ))}

            <div className={classNames("h-4 w-0.5", lineColorClass)}></div>
          </div>
        </div>
      </>
    );
  };

  const renderMarker = (
    sections: number,
    markers: number[],
    markerColorClass: string
  ) => {
    let gapClass = sections >= 3 ? "gap-[2.88rem]" : "gap-[4.88rem]";
    return (
      <>
        <div
          className={classNames(
            "flex justify-center w-full items-center",
            gapClass
          )}
        >
          {markers.map((marker) => (
            <Text
              variant="caption2-regular"
              className={classNames(markerColorClass)}
            >
              {marker}
            </Text>
          ))}
        </div>
      </>
    );
  };

  const renderLabel = (
    section: number,
    labels: string[],
    labelColorClass: string
  ) => {
    const labelMarginRight = labels.length === 2 ? "mr-15" : "mr-7";
    const labelMarginLeft = labels.length === 2 ? "ml-12" : "ml-8";
    return (
      <div className="flex items-center justify-center w-full">
        <div
          className={classNames(
            "flex w-[160px] items-center ",
            labelMarginLeft
          )}
        >
          {labels.map((label) => (
            <Text
              variant="caption2-regular"
              className={classNames(labelColorClass, labelMarginRight)}
            >
              {label}
            </Text>
          ))}
        </div>
      </div>
    );
  };

  const renderPointer = (
    section: number,
    position: number,
    pointerColorClass: string,
    bgClass: string
  ) => {
    let leftPosition = "left-6";
    if (section >= 3) {
      leftPosition =
        position === 1 ? "left-13.5" : position === 2 ? "left-25" : "left-40";
    } else if (section === 2) {
      leftPosition = position === 1 ? "left-14" : "left-35.5";
    }
    return (
      <>
        <div className="h-12 relative">
          <div
            className={classNames(
              "absolute -top-6 -translate-x-1/2",
              leftPosition
            )}
          >
            <Text
              variant="header-h1"
              className={classNames(
                "px-4 py-1 text-white font-bold rounded-lg border-none min-w-16 flex items-center justify-center",
                bgClass
              )}
            >
              {value}
            </Text>
            <div className="relative">
              <div
                className={classNames(
                  `w-0 h-0 absolute left-[35%] top-[-1px] border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] `,
                  pointerColorClass
                )}
              ></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const getColorTheme = (key: string) => {
    switch (key) {
      case "too-high":
        return {
          line: "bg-line-too-high",
          bg: "bg-background-too-high",
          pointerBorder: "border-t-label-too-high",
          pointer: "bg-label-too-high",
          text: "text-text-too-high",
        };
      case "high":
        return {
          line: "bg-line-high",
          bg: "bg-background-high",
          pointerBorder: "border-t-label-high",
          pointer: "bg-label-high",
          text: "text-text-high",
        };
      case "normal":
        return {
          line: "bg-line-normal",
          bg: "bg-background-normal",
          pointerBorder: "border-t-label-normal",
          pointer: "bg-label-normal",
          text: "text-text-normal",
        };
      case "low":
        return {
          line: "bg-line-low",
          bg: "bg-background-low",
          pointerBorder: "border-t-label-low",
          pointer: "bg-label-low",
          text: "text-text-low",
        };
      default:
        return {
          line: "bg-line-normal",
          bg: "bg-background-normal",
          pointerBorder: "border-t-label-normal",
          pointer: "bg-label-normal",
          text: "text-text-normal",
        };
    }
  };

  return (
    <div>
      <div
        className={classNames(
          "w-50 h-22 rounded-xl py-1 flex flex-col relative items-start ",
          getColorTheme(data.type).bg
        )}
      >
        {renderPointer(
          sections,
          position,
          getColorTheme(data.type).pointerBorder,
          getColorTheme(data.type).pointer
        )}
        {renderLabel(sections, labels, getColorTheme(data.type).text)}
        {renderLines(sections, getColorTheme(data.type).line)}
        {renderMarker(sections, markers, getColorTheme(data.type).text)}
      </div>
    </div>
  );
};

export default Range;
