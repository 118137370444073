import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { RootState } from "src/store";
import {
  cancelAppointmentRequest,
  getAppointmentListRequestAction,
} from "src/store/slices/appointmentSlice";
import sendMessageToParent from "src/helpers/parentMessageHandler";

import Button from "src/components/atoms/Button/Button";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";
import Modal from "src/components/atoms/Modal/Modal";
import { HeaderV2 } from "src/components/molecules/HeaderV2";
import CancelAppointmentModal from "src/components/organisms/appointment /CancelAppointmentModal";
import AppointmentCard from "src/components/organisms/appointment /AppointmentCard";

import { Appointment } from "src/types/appointment";
import { LOCAL_STORAGE } from "src/constants/common";

const AppointmentPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { appointmentList } = useSelector(
    (state: RootState) => state.appointment
  );
  const { user } = useSelector((state: RootState) => state.auth);
  const [page, setPage] = useState<number>(1);
  const [cancelRequestModal, setCancelRequestModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment>();

  useEffect(() => {
    fetchAppointments(page);
  }, []);

  const fetchAppointments = (page: number) => {
    setLoading(true);
    let payload = {
      page_no: page.toString(),
    };
    new Promise((resolve, reject) => {
      dispatch(
        getAppointmentListRequestAction({
          payload,
          resolve,
          reject,
        })
      );
    }).finally(() => {
      setLoading(false);
    });
  };

  const onPressBack = async () => {
    const queryParams = await localStorage.getItem(LOCAL_STORAGE.QUERY_PARAMS);
    if (queryParams) {
      const parsedParams = JSON.parse(queryParams);
      if (parsedParams?.moduleName.toLowerCase() === "appointment") {
        sendMessageToParent("NAVIGATE_BACK");
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };

  const pressBookButton = () => {
    navigate("/appointment/book-coach");
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = event.target as HTMLDivElement;

    if (
      target.scrollHeight - target.scrollTop <= target.clientHeight + 100 &&
      !appointmentList.isLoading &&
      !appointmentList.isLastPageReached
    ) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchAppointments(nextPage);
    }
  };

  const cancelRequest = () => {
    setLoading(true);
    setCancelRequestModal(false);
    const payload = {
      appointment_id: selectedAppointment?.appointment_id,
      type: selectedAppointment?.type,
    };
    new Promise((resolve, reject) => {
      dispatch(
        cancelAppointmentRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((res: any) => {
        setPage(1);
        fetchAppointments(1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="bg-background h-full">
      <HeaderV2
        backButton={true}
        rightButton={!!user.data.paidUser}
        title="Your Appointments"
        rightButtonComponent={
          <>
            {!!user.data.paidUser && (
              <Button
                className="!px-3.5 !py-1.5 !rounded-md"
                label="Book"
                labelClassname="!font-medium text-sm"
              />
            )}
          </>
        }
        onClickOfRightBtn={pressBookButton}
        onClickOfBackBtn={onPressBack}
      />
      {loading && page === 1 ? (
        <div className="flex justify-center h-[100vh]">
          <SpinnerLoader size={35} />
        </div>
      ) : appointmentList.data?.length > 0 ? (
        <div
          className="px-3 h-[calc(100dvh-76px)] overflow-scroll gap-4 flex flex-col "
          onScroll={handleScroll}
        >
          {appointmentList.data.map((data: Appointment) => (
            <AppointmentCard
              appointment={data}
              handleCancelRequest={() => {
                setSelectedAppointment(data);
                setCancelRequestModal(true);
              }}
            />
          ))}
          {loading && page > 1 && (
            <div className="flex justify-center h-[10vh]">
              <SpinnerLoader size={35} />
            </div>
          )}
        </div>
      ) : (
        <div className="w-full h-[93%] bg-background flex items-center justify-center">
          <div className="text-primary font-semibold text-sm">
            Looks like you have not booked any Appointment(s).
          </div>
        </div>
      )}
      {cancelRequestModal && (
        <Modal
          onClose={() => {
            setCancelRequestModal(false);
          }}
          closeIcon={false}
          className="rounded-none !p-3"
        >
          <CancelAppointmentModal
            message="Are you sure want to cancel appointment?"
            onSuccessFunction={() => {
              cancelRequest();
            }}
            onCancelFunction={() => {
              setCancelRequestModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default AppointmentPage;
