/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import moment from "moment";

import Card from "src/components/atoms/Card/Card";

import CalendarIcon from "src/assets/icons/calendarRound.svg";
import ClockIcon from "src/assets/icons/clock.svg";
import RightArrowIcon from "src/assets/icons/red-arrow-right.svg";

import { Appointment } from "src/types/appointment";

interface AppointmentCardPropsType {
  appointment: Appointment;
  handleCancelRequest: () => void;
}

const AppointmentCard: React.FC<AppointmentCardPropsType> = (props) => {
  const { appointment, handleCancelRequest } = props;

  const renderLabel = (type: string) => {
    const labelConfig = {
      Missed: { bg: "bg-[#FAB000]", text: "Missed" },
      Cancelled: { bg: "bg-[#FF3333]", text: "Cancelled" },
      Complete: { bg: "bg-green-500", text: "Completed" },
      Scheduled: { bg: "bg-[#756FA9]", text: "Scheduled" },
    };
    const config = labelConfig[type as keyof typeof labelConfig];

    if (!config) return null;
    return (
      <div
        className={`absolute -top-1 -right-1 ${config.bg} text-white text-sm px-6 py-[6px] rounded-md`}
      >
        {config.text}
      </div>
    );
  };

  const timeOfDay = (date: string) => {
    const hour = new Date(date).getHours();
    if (hour >= 6 && hour < 12) return "Morning";
    if (hour >= 12 && hour < 18) return "Afternoon";
    return "Evening";
  };

  return (
    <div className="first:mt-2">
      <Card className="relative p-4  overflow-hidden rounded-xl">
        {renderLabel(appointment.appointment_status)}
        <div className="flex border-b border-b-gray-300 pb-4">
          <div>
            <img
              src={appointment.profile_picture}
              className="rounded-full h-12 w-12 mr-4 object-cover"
            />
          </div>
          <div>
            <div className="font-semibold">{appointment.doctor_name}</div>
            <div className="text-xs text-gray-600">{appointment.role}</div>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex">
            <div className="mr-2">
              <img src={CalendarIcon} className="h-5 w-5" />
            </div>
            <div className="text-sm font-semibold">
              {moment(appointment.appointment_date).format(
                "dddd, DD MMMM, YYYY"
              )}
            </div>
          </div>
          <div className="flex mt-2">
            <div className="mr-2">
              <img src={ClockIcon} className="h-5 w-5" />
            </div>
            <div className="text-sm font-semibold">
              {timeOfDay(appointment.appointment_start_time)},{" "}
              {appointment.appointment_time}
            </div>
          </div>
        </div>
        {!["Cancelled", "Missed", "Complete"].includes(
          appointment.appointment_status
        ) ? (
          <div className="flex-row items-center justify-between mt-2.5 pl-1 text-[#1F2933]">
            <p>
              The coach will connect and share a meeting link at the time of
              your scheduled call.
            </p>
            <div
              className={"my-2.5 text-primary flex items-center"}
              onClick={() => {
                if (appointment.appointment_status !== "Missed")
                  handleCancelRequest();
              }}
            >
              Request Cancellation
              <img src={RightArrowIcon} className="ml-2 pt-1" />
            </div>
            {/* <Button
              label="Join Call"
              className="!py-1 !px-8 rounded-md h-10"
              disabled={!appointment.action}
              labelClassname={!appointment.action ? "text-white" : ""}
            /> */}
          </div>
        ) : null}
      </Card>
    </div>
  );
};

export default AppointmentCard;
