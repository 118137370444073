import React, { FC, useEffect } from "react";
import ProgressBackIcon from "src/components/atoms/ProgressBackIcon/ProgressBackIcon";
import Text from "src/components/atoms/Text";
import SyncButton from "src/components/molecules/Devices/SyncButton";
import ScaleImage from "src/assets/images/devices/scale.png";
import RightArrowIcon from "src/assets/icons/progress/blueRightArrow.svg";
import { useNavigate } from "react-router-dom";
const DeviceDetailsScreen: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="h-full">
      <div className="border border-b border-[#E4E7EC]">
        <ProgressBackIcon
          onClick={() => {
            navigate("/progress/progress-device-section?active_tab");
          }}
        />
      </div>
      <div className="p-4 flex flex-col gap-10 bg-gradient-to-b from-white to-[#FDFBF5]">
        <div>
          <Text variant="headline-large" className="w-full">
            GoodFlip Smart Scale
          </Text>
        </div>
        <div className="w-full flex items-center justify-center">
          <div className="bg-[#00000033] p-4 rounded-xl">
            <img src={ScaleImage} className="rounded-xl h-62 w-60" />
          </div>
        </div>
      </div>
      <div className="relative -top-35.5">
        <div
          className=" h-40 bg-white z-10"
          style={{ clipPath: "ellipse(65% 100px at 50% 100%)" }}
        ></div>
        <div className="flex items-center justify-center top-9 absolute left-[41%] border-white bg-white p-2 rounded-full">
          <SyncButton height="h-14" width="w-14" />
        </div>
        <div className="p-4 pt-0 relative -top-5">
          <div className="flex items-center justify-center gap-3">
            <div className="w-16 h-[74px] rounded-xl bg-[#FFFBEE] overflow-hidden">
              <div className="bg-background-light-yellow h-3 "></div>
              <div className="flex flex-col items-center justify-center pt-2 gap-2">
                <div className="flex items-center justify-center gap-1">
                  <Text
                    variant="body-large"
                    className="text-text-primary font-bold"
                  >
                    01
                  </Text>
                  <Text
                    variant="label-small"
                    className="text-text-primary font-bold"
                  >
                    Jul
                  </Text>
                </div>
                <Text
                  variant="label-small"
                  className="font-normal text-text-secodary"
                >
                  12:00 PM
                </Text>
              </div>
            </div>
            <div className="h-16 bg-color-accent-indigo w-[77%] rounded-xl px-4 py-3 flex items-start justify-between">
              <div className=" flex flex-col gap-0.5">
                <Text variant="label-large" className="text-[#2D3282]">
                  Log #32
                </Text>
                <div className="flex">
                  <Text variant="label-medium" className="text-text-secodary">
                    Weight:
                  </Text>
                  <Text
                    variant="label-medium"
                    className="text-text-secodary font-bold pl-1"
                  >
                    78 kgs
                  </Text>
                </div>
              </div>
              <div>
                <img src={RightArrowIcon} />
              </div>
            </div>
          </div>
          <div className="h-10 border-l-2 border-dashed  border-[#E7C144] relative left-[8%] "></div>
        </div>
      </div>
    </div>
  );
};

export default DeviceDetailsScreen;
