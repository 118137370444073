import { methods, request } from "./base";

const appointmentApis = {
  getAppointmentListData: (payload?: object) => {
    const route = "/doctor/get_appointment_list";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
  cancelAppointment: (payload?: object) => {
    const route = "/doctor/cancel_appointment";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
  getTimeSlots: (payload: object) => {
    const route = "/doctor/appointment_time_slot";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
  addAppointment: (payload: object) => {
    const route = "/doctor/add_appointment";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
};

export default appointmentApis;
