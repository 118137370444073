import React, { useEffect, useState } from "react";

import HomeHealth from "src/components/organisms/landing/HomeHealth";
import LandingHeader from "src/components/molecules/landing/LandingHeader";
import ProgressCard from "src/components/molecules/landing/ProgressCard";
import { useUrlParams } from "src/helpers/common";
import { useNavigate } from "react-router-dom";

const HomePage: React.FC = () => {
  const [loader, setLoader] = useState(true);
  const paramsData = useUrlParams<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (paramsData?.moduleName == "progress") {
      navigate("/progress");
    } else if (paramsData?.moduleName == "appointment") {
      navigate("/appointment/my-appointments");
    }
  }, [paramsData?.moduleName]);

  return (
    <div className="h-full">
      {!loader && (
        <>
          {paramsData?.moduleName == "" && <LandingHeader className="" />}
          {["progress"].includes(paramsData?.moduleName?.toLowerCase()) ||
            (paramsData?.moduleName == "" && <ProgressCard className="" />)}
        </>
      )}
      {/* {["exercise", "diet"].includes(paramsData?.moduleName?.toLowerCase()) ||
        (paramsData?.moduleName == "" && (
          <HomeHealth setLoaderHomePage={setLoader} />
        ))} */}

      {(["exercise", "diet"].includes(paramsData?.moduleName?.toLowerCase()) ||
        !paramsData?.moduleName) && (
        <HomeHealth setLoaderHomePage={setLoader} />
      )}
    </div>
  );
};

export default HomePage;
