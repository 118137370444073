import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { getTimeSlotRequestAction } from "src/store/slices/appointmentSlice";
import useQueryParams from "src/hooks/useQueryParams";
import { bindQueryParams } from "src/helpers/common";
import { RootState } from "src/store";

import Button from "src/components/atoms/Button/Button";
import Calendar from "src/components/atoms/Calendar";
import Card from "src/components/atoms/Card/Card";
import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import { Header } from "src/components/molecules/Header";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";

const BookAppointmentSlotPage: FC = () => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const coachType = queryParams.coach;

  const { appointmentTimeSlot } = useSelector(
    (state: RootState) => state.appointment
  );
  const [selectedDate, setSelectedDate] = useState(
    queryParams.selected_date
      ? moment(queryParams.selected_date, "DD-MM-YYYY").toDate()
      : new Date()
  );
  const [selectedSlot, setSelectedSlot] = useState<{
    time: string;
    title: string;
  }>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchTimeSlots();
  }, [selectedDate]);

  const fetchTimeSlots = () => {
    setLoading(true);
    setSelectedSlot({
      time: "",
      title: "",
    });
    const payload: any = {
      appointment_date: moment(selectedDate).format("DD-MM-YYYY"),
      consulation_type: "Video",
      health_coach_id: queryParams.health_coach_Id,
      type: "Coach",
    };
    new Promise((resolve, reject) => {
      dispatch(
        getTimeSlotRequestAction({
          payload,
          resolve,
          reject,
        })
      );
    }).finally(() => {
      setLoading(false);
    });
  };

  const getTitle = () => {
    return coachType === "nutritionist"
      ? "Nutritionist"
      : coachType === "physiotherapist"
      ? "Physiotherapist"
      : "Success Coach";
  };

  const onPressBack = () => {
    navigate("/appointment/book-coach");
  };

  const onPressReview = () => {
    navigate(
      bindQueryParams("/appointment/confirm-slot", {
        coach: coachType,
        health_coach_Id: queryParams.health_coach_Id,
        selected_date: moment(selectedDate).format("DD-MM-YYYY"),
        title: selectedSlot?.title,
        time_slot: selectedSlot?.time,
      })
    );
  };

  const disabledDate = (current: Date): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const currentDate = new Date(current);
    currentDate.setHours(0, 0, 0, 0);
    return currentDate < today;
  };

  return (
    <div className="bg-background h-full">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div>
            <Header
              backButton={true}
              rightButton={false}
              title={`With ${getTitle()}`}
              onClickOfBackBtn={onPressBack}
            />
            <Calendar
              selectedDate={selectedDate}
              onSelect={setSelectedDate}
              disabledDate={disabledDate}
            />
          </div>
        }
        component2={
          <>
            {loading ? (
              <div className="flex justify-center h-full">
                <SpinnerLoader size={35} />
              </div>
            ) : appointmentTimeSlot.data.length > 0 ? (
              <div>
                <div className="p-4 pb-0">
                  <div className="font-semibold text-lg">
                    Select Appointment Time
                  </div>
                  <div className="mt-3 overflow-auto h-[calc(100dvh-392px)]">
                    {appointmentTimeSlot.data.map((data) => (
                      <Card className="p-6 mb-4">
                        <div className="text-s font-bold">{data.title}</div>
                        <div className="mt-4 flex gap-3 flex-wrap">
                          {data.slots.map((slot) => (
                            <div
                              className={classNames(
                                "border mt-3 border-gray-300 text-text-secodary px-2 py-2 w-36 flex items-center justify-center text-sm rounded-lg",
                                selectedSlot?.title === data.title &&
                                  slot === selectedSlot.time
                                  ? "border-primary bg-background-sixth"
                                  : "border-gray-300"
                              )}
                              onClick={() =>
                                setSelectedSlot({
                                  time: slot,
                                  title: data.title,
                                })
                              }
                            >
                              <div>{slot.trim()}</div>
                            </div>
                          ))}
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
                <div className=" p-4 bg-white sticky bottom-0">
                  <Button
                    label="Review Details"
                    className={"w-full !rounded-xl"}
                    disabled={
                      selectedSlot && selectedSlot.time.length ? false : true
                    }
                    onClick={onPressReview}
                  ></Button>
                </div>
              </div>
            ) : (
              <div className=" p-2 text-sm text-text-secodary flex items-center justify-center h-full text-center">
                Looks like, there are no slots available, please check for a
                different date to get the slots
              </div>
            )}
          </>
        }
      />
    </div>
  );
};

export default BookAppointmentSlotPage;
