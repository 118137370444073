import React from "react";

interface TextInputAnimatedProps {
  id: string;
  label: string;
  value: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  required?: boolean;
  readonly?: boolean;
  handleOnClick?: () => void;
  width?: string;
  height?: string;
  placeholder?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
}

const TextInputAnimated: React.FC<TextInputAnimatedProps> = ({
  id,
  label,
  value,
  onChange = () => { },
  className = "",
  required = false,
  readonly = false,
  handleOnClick,
  width = "100%",
  height = "auto",
  placeholder = "",
  onFocus,
}) => {
  return (
    <div
      className={`relative w-full select-none shadow-lg ${className}`}
      style={{ width }}
    >
      <input
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder || " "}
        required={required}
        readOnly={readonly}
        onClick={handleOnClick}
        className="block border border-black select-none rounded-2xl px-3.5 pb-2.5 pt-5 w-full text-sm font-medium text-gray-900 focus:border-text-primary peer focus:ring-0"
        style={{ height }}
        onFocus={onFocus}
        tabIndex={-1}
      />
      <label
        htmlFor={id}
        className="absolute select-none text-sm text-black dark:text-text-subtitle duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-3.5 peer-focus:text-text-subtitle peer-focus:dark:text-text-subtitle peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
      >
        {label}
      </label>
    </div>
  );
};

export default TextInputAnimated;
