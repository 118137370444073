const environment = {
  API_KEY: process.env.REACT_APP_API_KEY,
  API_BASE_URL: process.env.REACT_APP_API_ENDPOINT,
  API_ENC_KEY: process.env.REACT_APP_ENCRYPTION_KEY,
  API_ENV_IV: process.env.REACT_APP_ENCRYPTION_IV,
  MOENGAGE_APP_ID: process.env.REACT_APP_MOENGAGE_APP_ID,
  MO_ENGAGE_DATA_CENTER: process.env.REACT_APP_MO_ENGAGE_DATA_CENTER,
  MO_ENGAGE_ENABLE_DEBUG_LOGS:
    process.env.REACT_APP_MO_ENGAGE_ENABLE_DEBUG_LOGS,
};

export default environment;
