import React, { useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useQueryParams from "src/hooks/useQueryParams";

import MicronutrientsInformation from "src/components/organisms/search-food-macronutrients/MacronutrientsInfo";
import AddDiet from "src/components/organisms/search-food-macronutrients/AddDiet";
import { Header } from "src/components/molecules/Header";

import { hideLoader, showLoader } from "src/store/slices/loaderSlice";
import {
  getFoodNutritionsDetailsRequest,
  logFoodItemRequest,
} from "src/store/slices/foodDiarySlice";
import { RootState } from "src/store";
import { dateConverstion } from "src/helpers/date";
import { trackEvent } from "src/analytics";
import { EVENT_NAMES } from "src/analytics/events";

const SearchFoodResultPage: React.FC = () => {
  const { dietPlan, logFoodItem } = useSelector(
    (state: RootState) => state.foodDiary
  );
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const [itemQuantity, setItemQuantity] = React.useState<string>(
    !queryParams?.quantity || queryParams?.quantity === "0"
      ? "1"
      : queryParams?.quantity
  );

  useEffect(() => {
    if (!dietPlan?.data) navigate("/diet");
    showLoader();
    const payload = {
      food_id: queryParams?.foodItemId.toString(),
      unit_id: queryParams?.unitOptionId.toString(),
    };
    new Promise((resolve, reject) => {
      dispatch(
        getFoodNutritionsDetailsRequest({
          payload,
          resolve,
          reject,
        })
      );
    }).finally(() => {
      hideLoader();
    });
  }, []);

  const onPressAddOrUpdateBtn = () => {
    let payload: any = {
      meal_types_id: queryParams?.mealTypeId,
      food_item_id: queryParams?.foodItemId.toString(),
      food_name: queryParams?.foodName,
      quantity: itemQuantity.toString(),
      unit_option_id: queryParams?.unitOptionId.toString(),
      patient_id: user.data.patientID,
      achieved_datetime: dateConverstion(
        dietPlan.date,
        "YYYY-MM-DD hh:mm:ss",
        "YYYY-MM-DD hh:mm:ss",
        true
      ),
      is_active: "Y",
    };
    if (!moment().isAfter(dietPlan.date)) {
      payload = {
        ...payload,
        is_active: "N",
      };
    }
    if (!!queryParams?.foodItemLogId) {
      payload = {
        ...payload,
        patient_food_item_logs_id: queryParams?.foodItemLogId,
      };
    }

    if (!!queryParams?.diet_plan_meal_options_rel_id) {
      payload = {
        ...payload,
        diet_plan_meal_options_rel_id:
          queryParams?.diet_plan_meal_options_rel_id,
      };
    }

    payload = {
      ...payload,
      type: !!queryParams?.type ? queryParams?.type : "manual",
    };
    const tempPayload = {
      payload,
      type: (queryParams?.buttonType ?? "add")?.toLowerCase(),
    };
    new Promise((resolve, reject) => {
      dispatch(
        logFoodItemRequest({
          payload: tempPayload,
          resolve,
          reject,
        })
      );
    }).then((res: any) => {
      if (Number(res.code) === 1) {
        // DeviceEventEmitter.emit('dietScreenApi', {
        //   consumptionByDate: true,
        // });
        // dispatch(actions.healthSectionRefresh({isHealthRefresh: true}));
        // navigation.navigate('DietScreen');
        if (queryParams?.buttonType === "Add") {
          trackEvent(EVENT_NAMES.SEARCHED_FOOD_ITEM_CONSUMED, {
            food_item_name: queryParams?.foodName,
            module: "Diet",
            meal: queryParams?.mealName,
            date: dietPlan.date,
            tag: "manual",
          });
        }
        navigate(
          `/diet?date=${dietPlan.date}&mealName=${queryParams?.mealName}`
        );
      } else {
        if (queryParams?.buttonType === "Add") {
          trackEvent(EVENT_NAMES.ADD_DIET, {
            plan_assigned: "N",
          });
        }
      }
    });
  };

  return (
    <div className="relative bg-background h-full">
      <Header
        title={queryParams?.foodName}
        backButton
        onClickOfBackBtn={() => navigate("/diet")}
      />

      <MicronutrientsInformation itemQuantity={itemQuantity} />

      <AddDiet
        foodName={queryParams?.foodName}
        mealName={queryParams?.mealName}
        unitName={queryParams?.unitName}
        buttonText={queryParams?.buttonType ?? "Add"}
        onPressAddOrUpdateBtn={onPressAddOrUpdateBtn}
        itemQuantity={itemQuantity}
        updateItemQuantity={(qty) => {
          setItemQuantity(qty);
        }}
        isUpdating={logFoodItem.isLoading}
      />
    </div>
  );
};

export default SearchFoodResultPage;
