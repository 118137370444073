import classNames from "classnames";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import Text from "src/components/atoms/Text";
import Range from "src/components/molecules/Devices/Range";
import BCAVitalView from "src/components/molecules/Devices/BCAVitalView";
import useQueryParams from "src/hooks/useQueryParams";

import BCABMI from "src/assets/images/BCA/bca_bmi.svg";
import BCABMR from "../../../../assets/images/BCA/bca_bmr.svg";
import BCABodyFat from "../../../../assets/images/BCA/bca_body_fat.svg";
import BCABodyWeight from "../../../../assets/images/BCA/bca_body_weight.svg";
import BCABoneMass from "../../../../assets/images/BCA/bca_bone_mass.svg";
import BCAHydration from "../../../../assets/images/BCA/bca_hydration.svg";
import BCAMetabolicAge from "../../../../assets/images/BCA/bca_metabolic_age.svg";
import BCAMuscleMass from "../../../../assets/images/BCA/bca_muscle_mass.svg";
import BCAProtein from "../../../../assets/images/BCA/bca_protein.svg";
import BCASkeletalMuscle from "../../../../assets/images/BCA/bca_skeletal_mass.svg";
import BCASubcutaneousFat from "../../../../assets/images/BCA/bca_subcutaneous_fat.svg";
import BCAVisceralFat from "../../../../assets/images/BCA/bca_visceral_fat.svg";

interface ReadingCardPropsType {
  type?: string;
  readingData?: any;
}

const ReadingCard: FC<ReadingCardPropsType> = ({ type, readingData }) => {
  const BCA_KEY_PARAMETERS: { [key: string]: any } = {
    body_fat_percentage: {
      id: "body_fat_percentage",
      description:
        "Unveil the hidden layer that wraps around your body, protecting and shaping your form with style",
      icon: BCABodyFat,
      name: "Body Fat",
      unit: "%",
    },
    bone_mass: {
      id: "bone_mass",
      description:
        "Delve into your bone mass measurements to fortify your skeletal health and resilience against fractures and osteoporosis",
      icon: BCABoneMass,

      name: "Bone Mass",
      unit: "Kg",
    },
    BMI: {
      id: "BMI",
      description:
        "Find balance, find health. Gauge your BMI to understand your weight status and set achievable health goals",
      icon: BCABMI,
      name: "BMI",
      unit: "Kg/m²",
    },
    BMR: {
      id: "BMR",
      description:
        "Unlock the key to sustainable weight management. Calculate your BMR to tailor your calorie intake for lasting results",
      icon: BCABMR,
      name: "BMR",
      unit: "Kcal",
    },

    weight: {
      id: "body_weight",
      name: "Body Weight",
      unit: "Kg",
      description:
        "Tracking your body weight regularly helps you understand how your lifestyle, diet, and physical activities affect your overall health",
      icon: BCABodyWeight,
    },

    hydration: {
      id: "hydration",
      description:
        "Hydration refers to the body's state of having an adequate amount of water to function optimally",
      icon: BCAHydration,
      name: "Hydration",
      unit: "%",
    },
    metabolic_age: {
      id: "metabolic_age",
      description:
        "Ignite your metabolism and fuel your fitness journey. Track your metabolic rate to fine-tune your nutrition and exercise",
      icon: BCAMetabolicAge,
      name: "Metabolic Age",
      unit: "Years",
    },
    muscle_mass: {
      id: "muscle_mass",
      description:
        "Discover the strength within you! Measure your muscle mass to optimize your fitness routine and muscle-building efforts",
      icon: BCAMuscleMass,
      name: "Muscle Mass",
      unit: "Kg",
    },
    protein_rate: {
      id: "protein_rate",
      description:
        "Discover the protein power within, essential for muscle repair, growth, and overall well-being",
      icon: BCAProtein,
      name: "Protein",
      unit: "%",
    },
    skeletal_muscle_percent: {
      id: "skeletal_muscle_percent",
      description:
        "Go beyond the surface. Measure your visceral fat to assess your risk of chronic diseases and prioritize your health",
      icon: BCASkeletalMuscle,
      name: "Skeletal Muscle",
      unit: "%",
    },
    subcutaneous_fat_percent: {
      id: "subcutaneous_fat_percent",
      description:
        "Unveil the hidden layer that wraps around your body, protecting and shaping your form with style.",
      icon: BCASubcutaneousFat,
      name: "Subcutaneous Fat",
      unit: "%",
    },
    visceral_fat: {
      id: "visceral_fat",
      description:
        "Go beyond the surface. Measure your visceral fat to assess your risk of chronic diseases and prioritize your health",
      icon: BCAVisceralFat,
      name: "Visceral Fat",
      unit: "%",
    },
  };

  return (
    <div className="flex flex-col gap-6 mt-6">
      {readingData && readingData.length !== 0
        ? Object.keys(readingData)?.map((data: any) => {
            const parameter = BCA_KEY_PARAMETERS[data];
            if (!parameter) {
              return null;
            }
            return (
              <div
                className="bg-white p-4 pt-8 flex items-start justify-between gap-1 rounded-2xl"
                key={data}
              >
                <BCAVitalView parameter={parameter} />
                <Range
                  data={{
                    type: "normal",
                    position: 2,
                    sections: 2,
                    labels: ["Low", "Normal"],
                    markers: [1, 2, 3],
                    value: parseFloat(readingData[data] ?? "0")
                      .toFixed(2)
                      .toString(),
                  }}
                />
              </div>
            );
          })
        : "no data found"}
    </div>
  );
};

export default ReadingCard;
