import moengage from "@moengage/web-sdk";
import moment from "moment";
import { LOCAL_STORAGE } from "src/constants/common";
import environment from "src/constants/environment";

const moEngageUtils = {
  initialize: () => {
    moengage?.initialize({
      app_id: environment.MOENGAGE_APP_ID as string,
      cluster: environment.MO_ENGAGE_DATA_CENTER as string,
      debug_logs: parseInt(environment.MO_ENGAGE_ENABLE_DEBUG_LOGS ?? "0"),
    });
  },

  trackEvent: (eventName: string, eventData = {}) => {
    const clientSource =
      localStorage.getItem(LOCAL_STORAGE.CLIENT_SOURCE) || "default";
    moengage?.track_event(eventName, {
      ...eventData,
      client_source: clientSource,
    });
  },

  setUserAttributes: (attributes: any) => {
    try {
      const {
        dob,
        contactNo: contact_no,
        gender,
        name,
        paidUser: paid_user,
        patientID: patient_id,
        planName: plan_name,
        country_code,
        email,
        indication,
        dr_name,
        dr_phone,
        language,
        severity,
        current_plan_name,
        patient_support_program_name,
      } = attributes;
      moengage?.add_unique_user_id(country_code + contact_no);
      moengage?.add_user_name(name);
      moengage?.add_mobile(contact_no);
      moengage?.add_gender(
        gender === "M" ? "Male" : gender === "F" ? "Female" : ""
      );
      moengage?.add_birthday(moment(dob).toDate());
      moengage?.add_user_attribute("patient_id", patient_id);
      moengage?.add_user_attribute("pain_user", paid_user);
      moengage?.add_user_attribute("plan_name", plan_name);

      moengage?.add_email(email);
      moengage?.add_user_attribute("indication", indication);
      moengage?.add_user_attribute("dr_name", dr_name);
      moengage?.add_user_attribute("dr_phone", dr_phone);
      moengage?.add_user_attribute("language", language);
      moengage?.add_user_attribute("severity", severity);
      moengage?.add_user_attribute("current_plan_name", current_plan_name);
      if (patient_support_program_name) {
        moengage?.add_user_attribute(
          "patient_support_program_name",
          patient_support_program_name
        );
      }
    } catch (error) {
      console.error("Error while setting user attributes:", error);
      throw error;
    }
  },

  logout: () => {
    moengage?.destroy_session();
  },
};

export default moEngageUtils;
