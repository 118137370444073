import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { getHealthCoachListRequest } from "src/store/slices/healthCoachSlice";
import { RootState } from "src/store";

import Card from "src/components/atoms/Card/Card";
import { HeaderV2 } from "src/components/molecules/HeaderV2";

import ArrowForward from "src/assets/icons/common/arrow-forward.svg";
import CalendarIcon from "src/assets/icons/calendarRound.svg";
import ClockIcon from "src/assets/icons/clock.svg";

import { HealthCoachDataType } from "src/types/healthCoach";

type HealthCoachType = "nutritionist" | "physiotherapist" | "psychoanalyst";

const SelectHealthCoach: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { healthCoachList } = useSelector(
    (state: RootState) => state.healthCoach
  );
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    fetchHealthCoachList();
  }, []);

  const fetchHealthCoachList = () => {
    let payload = {
      patient_plan_rel_id: user.data.patient_plan_rel_id,
    };
    new Promise((resolve, reject) => {
      dispatch(
        getHealthCoachListRequest({
          payload,
          resolve,
          reject,
        })
      );
    });
  };

  const onPressBack = () => navigate("/appointment/my-appointments");

  const getDateTimeCard = () => (
    <Card className="p-3">
      <div className="my-4">
        <div className="flex">
          <div className="mr-2">
            <img src={CalendarIcon} className="h-5 w-5" />
          </div>
          <div className="text-sm">
            {/* {moment(queryParams["selected_date"]).format(
                  "dddd, DD MMMM, YYYY"
                )} */}
            Monday, 24 December,2024
          </div>
        </div>
        <div className="flex mt-2">
          <div className="mr-2">
            <img src={ClockIcon} className="h-5 w-5" />
          </div>
          <div className="text-sm">
            {/* {queryParams["period"]}, {queryParams["time_slot"]} */}
            Evening, 08:00 PM - 10:00 PM
          </div>
        </div>
      </div>
    </Card>
  );

  const canShowHealthCoach = (key: HealthCoachType): boolean => {
    const featureMap: Record<
      HealthCoachType,
      keyof typeof user.data.health_coach_feature_list
    > = {
      nutritionist: "nutri_coach",
      physiotherapist: "physio_coach",
      psychoanalyst: "success_coach",
    };
    return !!user.data.health_coach_feature_list[featureMap[key]];
  };

  const getCustomCard = (title: string, onArrowClick: () => void) => (
    <Card className="!p-4 mt-5 rounded-xl">
      <div className="font-semibold">{title}</div>
      <div
        className="p-3 mt-3 shadow-md rounded-lg w-full  border border-gray-300 flex justify-between items-center"
        onClick={() => onArrowClick()}
      >
        <div className="text-text-secodary font-light text-sm">
          Select Date & Time
        </div>
        <div>
          <img src={ArrowForward} alt="Next" className="w-6 h-3 " />
        </div>
      </div>
    </Card>
  );

  const showDirectCard = (key: HealthCoachType) => {
    const healthCoachListData = healthCoachList.data;

    const getDayConversion = (availabilityDate: any, dayKey: any) => {
      return dayKey || moment(availabilityDate, "YYYY-MM-DD").format("dddd");
    };

    const getTempDate = (day: any, date: any) => ({ day, date });

    const getTempTime = (timeType: any, startTime: any, endTime: any) => ({
      time: timeType,
      startTime,
      endTime,
    });

    const handleExpiredCheck = (
      tempDate: any,
      title: string,
      callback: any
    ) => {
      const isExpired = moment(tempDate.date).isBefore(moment(), "day");
      return isExpired ? getCustomCard(title, callback) : getDateTimeCard();
    };

    const processHealthCoach = (
      coachKey: HealthCoachType,
      title: string,
      availabilityDate: any,
      dayKey: any,
      timeType: any,
      startTime: any,
      endTime: any
    ) => {
      if (
        healthCoachListData &&
        healthCoachListData[coachKey] == null &&
        availabilityDate
      ) {
        const day = getDayConversion(availabilityDate, dayKey);
        const tempDate = getTempDate(day, availabilityDate);
        const tempTime = getTempTime(timeType, startTime, endTime);
        const { date, time } = dateTimeFormate(tempDate, tempTime);
        return handleExpiredCheck(tempDate, title, () => {
          onPressHealthCoach(coachKey);
        });
      } else {
        return getCustomCard(title, () => {
          onPressHealthCoach(coachKey);
        });
      }
    };

    if (key === "nutritionist" && canShowHealthCoach("nutritionist")) {
      return processHealthCoach(
        "nutritionist",
        "With Nutritionist",
        healthCoachListData?.nutritionist_availability_date,
        healthCoachListData?.nutritionist_day,
        healthCoachListData?.nutritionist_time_type,
        healthCoachListData?.nutritionist_start_time,
        healthCoachListData?.nutritionist_end_time
      );
    } else if (
      key === "physiotherapist" &&
      canShowHealthCoach("physiotherapist")
    ) {
      return processHealthCoach(
        "physiotherapist",
        "With Physiotherapist",
        healthCoachListData?.physiotherapist_availability_date,
        healthCoachListData?.physiotherapist_day,
        healthCoachListData?.physiotherapist_time_type,
        healthCoachListData?.physiotherapist_start_time,
        healthCoachListData?.physiotherapist_end_time
      );
    } else if (key === "psychoanalyst" && canShowHealthCoach("psychoanalyst")) {
      return processHealthCoach(
        "psychoanalyst",
        "With Success Coach",
        healthCoachListData?.psychoanalyst_availability_date,
        healthCoachListData?.psychoanalyst_day,
        healthCoachListData?.psychoanalyst_time_type,
        healthCoachListData?.psychoanalyst_start_time,
        healthCoachListData?.psychoanalyst_end_time
      );
    } else {
      return null;
    }
  };

  const dateTimeFormate = (date: any, time: any) => {
    const startTime = moment(time.startTime, "HH:mm:ss").format("h:mm A");
    const endTime = moment(time.endTime, "HH:mm:ss").format("h:mm A");
    return {
      date: `${date.day}, ${moment(date.date).format("DD MMM,YYYY")}`,
      time: `${time.time}, ${startTime} - ${endTime}`,
    };
  };

  const onPressHealthCoach = (key: HealthCoachType) => {
    const coachMapping = {
      nutritionist: "Nutritionist",
      physiotherapist: "Physiotherapist",
      psychoanalyst: "Success Coach",
    };

    const coachData = healthCoachList.data?.[key];
    const HcId = coachData?.health_coach_id;
    if (!HcId) {
      showToast(`Please wait until your ${coachMapping[key]} is assigned.`);
      return;
    }
    // TODO : if(feature is there in user then navigate)
    navigate(`/appointment/book-slot/?coach=${key}&health_coach_Id=${HcId}`);
  };

  const showToast = (message: string) => {
    toast.info(message, {
      position: "bottom-center",
      hideProgressBar: true,
      icon: <></>,
      autoClose: 3000,
      style: {
        backgroundColor: "gray",
        color: "white",
      },
      closeButton: ({ closeToast }) => <></>,
    });
  };

  return (
    <div className="bg-background h-full">
      <HeaderV2
        backButton={true}
        rightButton={false}
        title="Book Appointment"
        onClickOfBackBtn={onPressBack}
      />
      <div className="w-full bg-background px-3 mt-5">
        {showDirectCard("nutritionist")}
        {showDirectCard("physiotherapist")}
        {showDirectCard("psychoanalyst")}
      </div>
    </div>
  );
};

export default SelectHealthCoach;
