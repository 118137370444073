// src/store/slices/dietSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BCA, common } from "src/types/index";

// Initial state for the diet slice
const initialState: BCA.BCSSliceStateType = {
  BcaResult: {
    isLoading: false,
    data: "",
    error: "",
  },
};

// Define the diet slice
const bcaSlice = createSlice({
  name: "bca",
  initialState,
  reducers: {
    setBcaResultRequest: (
      state: BCA.BCSSliceStateType,
      _action: PayloadAction<any>
    ) => {
      state.BcaResult.isLoading = true;
      state.BcaResult.error = "";
    },
    setBcaResultSuccess: (
      state: BCA.BCSSliceStateType,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.BcaResult.isLoading = false;
    },
    setBcaResultError: (
      state: BCA.BCSSliceStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.BcaResult.isLoading = false;
    },
    downloadBcaReportRequest: (
      state: BCA.BCSSliceStateType,
      _action: PayloadAction<any>
    ) => {
      state.BcaResult.isLoading = true;
      state.BcaResult.error = "";
    },
    downloadBcaReportSuccess: (
      state: BCA.BCSSliceStateType,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.BcaResult.isLoading = false;
    },
    downloadBcaReportError: (
      state: BCA.BCSSliceStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.BcaResult.isLoading = false;
    },
  },
});

// Export actions and reducer
export const {
  setBcaResultRequest,
  setBcaResultSuccess,
  setBcaResultError,
  downloadBcaReportRequest,
  downloadBcaReportSuccess,
  downloadBcaReportError,
} = bcaSlice.actions;

export default bcaSlice.reducer;
