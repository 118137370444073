import React, { ReactNode } from "react";
import classNames from "classnames";
interface ModalProps {
  children: ReactNode;
  onClose: () => void;
  closeIcon?: boolean;
  className?: string;
}

const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  closeIcon = true,
  className = "",
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      <div
        className={classNames(
          className,
          "relative bg-white rounded-lg shadow-lg max-w-md  p-6"
        )}
      >
        {/* Close Icon */}
        {closeIcon && (
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 text-xl"
            aria-label="Close modal"
          >
            &times;
          </button>
        )}

        {/* Modal Content */}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
