import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "src/store";
import ProgressBackIcon from "src/components/atoms/ProgressBackIcon/ProgressBackIcon";
import Text from "src/components/atoms/Text";
import { THEME_COLORS } from "src/theme/colors";

import { ReactComponent as ShareIcon } from "src/assets/icons/progress/share.svg";
import DynamicSVG from "src/components/atoms/DynamicSVG";
import ScaleImage from "src/assets/images/devices/bca_detail_machine.png";
import ReadingCard from "src/components/organisms/Progress/ReadingCard";
import useQueryParams from "src/hooks/useQueryParams";
import { useDispatch } from "react-redux";
import {
  downloadBcaReportRequest,
  setBcaResultRequest,
} from "src/store/slices/bcaSlice";

const DeviceResultPage: FC = () => {
  const { clientSource } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = useQueryParams(location.search);
  const [dataUpdated, setDataUpdated] = useState<boolean>(false);
  let readingData = undefined;

  if (queryParams) {
    const parseData = JSON.parse(queryParams["eventData"] ?? "");
    readingData = JSON.parse(parseData.data);
  }

  const setBCAResult = (payload: any) => {
    new Promise((resolve, reject) => {
      dispatch(
        setBcaResultRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((data) => {
        console.log("res data=>", data);
        setDataUpdated(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onPressShare = () => {
    let payload = {};
    new Promise((resolve, reject) => {
      dispatch(
        downloadBcaReportRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((data: any) => {
        // console.log("data==>", data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="bg-background">
      <div className="border border-b border-[#E4E7EC]">
        <ProgressBackIcon
          onClick={() => {
            navigate("/progress/progress-device-section?active_tab=Devices");
          }}
        />
      </div>
      <div className="bg-color-accent-indigo pt-4 px-12  flex flex-col items-center justify-center overflow-hidden">
        <div className="flex flex-col gap-2 ">
          <Text variant="headline-medium" align="center">
            Body Composition Analysis Log
          </Text>
          <Text
            variant="body-medium"
            align="center"
            className="text-text-ternary"
          >
            GoodFlip app has received the following data from your smart scale
          </Text>
        </div>
        <div
          className="flex items-center justify-center gap-1 pt-3"
          onClick={onPressShare}
        >
          <Text
            variant="body2-bold"
            className="font-semibold text-button-green-primary"
          >
            Share Report
          </Text>
          <DynamicSVG
            size="h-3 w-3"
            color={THEME_COLORS[clientSource]["--color-button-green-primary"]}
          >
            <ShareIcon />
          </DynamicSVG>
        </div>
        <div className="relative">
          <div className="absolute top-10 left-[52%] z-10 -translate-x-1/2 flex">
            <Text
              variant="digital"
              className="text-2xl text-color-red-scale-value"
            >
              {readingData?.weight ?? "0"}
            </Text>
            <Text
              variant="caption1-regular"
              className=" text-color-red-scale-value"
            >
              kg
            </Text>
          </div>
          <img src={ScaleImage} className="h-auto w-61 relative top-6" />
        </div>
      </div>
      <div className="p-4 z-10">
        <div className="p-3 bg-background-light-yellow rounded-xl">
          <Text variant="label-small" className="text-text-primary">
            Last measurement taken on
          </Text>
          <Text variant="body-medium" className="font-bold text-text-primary">
            24th Jan 2025; 03:00 PM
          </Text>
        </div>
        <div>
          <ReadingCard readingData={readingData} />
        </div>
      </div>
    </div>
  );
};

export default DeviceResultPage;
