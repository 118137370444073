import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";

import ArrowForward from "src/assets/icons/common/arrow-forward.svg";
import ArrowDown from "src/assets/icons/common/down-arrow.svg";
import ArrowUp from "src/assets/icons/common/up-arrow.svg";

interface CalendarProps {
  selectedDate: Date;
  onSelect: (date: Date) => void;
  onChangeMonth?: (monthData: { month: number; year: number }) => void;
  showExpandArrow?: boolean;
  fullView?: boolean;
  className?: string;
  disabledDate?: (date: any) => boolean;
}

const Calendar: React.FC<CalendarProps> = (props) => {
  const {
    onSelect,
    onChangeMonth = () => {},
    selectedDate,
    showExpandArrow = true,
    fullView = false,
    className = "",
    disabledDate = () => {},
  } = props;

  const [viewStartDate, setViewStartDate] = useState<Date>(
    new Date(selectedDate)
  );

  const [showFullMonth, setShowFullMonth] = useState<boolean>(
    fullView ?? false
  );

  useEffect(() => {
    setViewStartDate(new Date(selectedDate));
  }, [selectedDate]);

  // Get the first day of the month (1-7, where 1 is Monday and 7 is Sunday)
  const getFirstDayOfMonth = (date: Date): number => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return firstDay === 0 ? 6 : firstDay - 1; // Adjust Sunday to be the last day of the week
  };

  // Get the number of days in the current month
  const getDaysInMonth = (date: Date): number =>
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

  // Handle navigation between months or weeks
  const handleNavigation = (direction: "next" | "prev"): void => {
    setViewStartDate((prevDate) => {
      const newDate = new Date(prevDate);
      const increment = direction === "next" ? 1 : -1;

      if (showFullMonth) {
        newDate.setMonth(newDate.getMonth() + increment);
        onChangeMonth({
          month: newDate.getMonth() + 1,
          year: prevDate.getFullYear(),
        });
      } else {
        newDate.setDate(newDate.getDate() + 7 * increment);
        onChangeMonth({
          month: newDate.getMonth() + 1,
          year: prevDate.getFullYear(),
        });
      }
      return newDate;
    });
  };

  // Toggle between full month and weekly view
  const toggleCalendarView = (): void => setShowFullMonth((prev) => !prev);

  // Get the class name for each day based on its status (taken, skipped, etc.)
  const getDayClass = (date: Date): string => {
    const dateString = moment(date).format("YYYY-MM-DD");
    if (dateString === moment(selectedDate).format("YYYY-MM-DD"))
      return "bg-primary text-white"; // Selected day
    return "";
  };

  // Generate the days for the calendar (full month view)
  const generateCalendarDays = (): JSX.Element[] => {
    const firstDayOfMonth = getFirstDayOfMonth(viewStartDate);
    const daysInMonth = getDaysInMonth(viewStartDate);

    const generateDay = (day: number): JSX.Element => {
      const date = new Date(
        viewStartDate.getFullYear(),
        viewStartDate.getMonth(),
        day
      );
      const isDisabled = disabledDate(date);
      const dayClass = getDayClass(date);

      return (
        <div
          key={`current-${day}`}
          onClick={!isDisabled ? () => onSelect(date) : undefined}
          className={`p-2 text-sm rounded-2xl text-center cursor-pointer ${
            isDisabled
              ? `${dayClass} text-text-disabled cursor-no-drop`
              : `${dayClass} text-text-secondary`
          }`}
        >
          {day}
        </div>
      );
    };

    const days = Array.from({ length: firstDayOfMonth }, (_, i) => (
      <div key={`empty-${i}`} className="p-2" />
    )).concat(
      Array.from({ length: daysInMonth }, (_, i) => generateDay(i + 1))
    );

    return days;
  };

  // Generate the days for the current week (weekly view)
  const getCurrentWeek = (): JSX.Element[] => {
    const weekStart = new Date(viewStartDate);
    weekStart.setDate(
      weekStart.getDate() -
        weekStart.getDay() +
        (weekStart.getDay() === 0 ? -6 : 1)
    );

    return Array.from({ length: 7 }, (_, index) => {
      const day = new Date(weekStart);
      day.setDate(day.getDate() + index);
      const isDisabled = disabledDate(day);
      const dayClass = getDayClass(day);

      return (
        <div
          key={`week-day-${index}`}
          onClick={!isDisabled ? () => onSelect(day) : undefined}
          className={`p-2 text-sm rounded-2xl text-center cursor-pointer ${
            isDisabled
              ? `${dayClass} text-text-disabled cursor-no-drop`
              : `${dayClass} text-text-secondary`
          }`}
        >
          {day.getDate()}
        </div>
      );
    });
  };

  // Get the header text for the calendar (month or week range)
  const getHeaderText = (): string => {
    if (showFullMonth) {
      return viewStartDate.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
    } else {
      const weekStart = moment(viewStartDate).startOf("isoWeek");
      const weekEnd = moment(weekStart).add(6, "days");
      return `${weekStart.format("DD MMM")} - ${weekEnd.format("DD MMM")}`;
    }
  };

  return (
    <div
      className={classNames(
        "max-w-4xl mx-auto p-4 bg-background rounded-b-lg shadow-lg",
        className
      )}
    >
      <section className="flex justify-between items-center mb-4">
        <span className="text-text-secodary text-s">{getHeaderText()}</span>
        <div className=" flex items-center">
          <button
            className="bg-transparent text-text-secodary border-none cursor-pointer"
            onClick={() => handleNavigation("prev")}
          >
            <img src={ArrowForward} alt="Next" className="w-6 h-3 rotate-180" />
          </button>
          <button
            className="bg-transparent text-text-secodary border-none cursor-pointer"
            onClick={() => handleNavigation("next")}
          >
            <img src={ArrowForward} alt="Next" className="w-6 h-3" />
          </button>
        </div>
      </section>
      <section className="grid grid-cols-7 gap-2">
        {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
          <div key={day} className="p-1 text-sm text-center text-text-secodary">
            {day}
          </div>
        ))}
        {showFullMonth ? generateCalendarDays() : getCurrentWeek()}
      </section>
      {showExpandArrow && (
        <div className="flex justify-center">
          <button
            className="mt-4 bg-transparent text-text-secodary cursor-pointer"
            onClick={toggleCalendarView}
          >
            {showFullMonth ? (
              <img src={ArrowDown} alt="Collapse" className="w-6" />
            ) : (
              <img src={ArrowUp} alt="Expand" className="w-6" />
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default Calendar;
