import { bindQueryParams } from "src/helpers/common";
import { methods, request } from "./base";

const BcaApis = {
  setBCAReadings: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/goal_readings/update_bca_readings",
      priv: true,
      payload,
    }),
  downloadBCAReport: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/goal_readings/generate_bca_report",
      priv: true,
      payload,
    }),
};

export default BcaApis;
