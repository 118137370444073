import React from "react";

import Button from "src/components/atoms/Button/Button";
import Input from "src/components/atoms/TextInput";

interface Props {
  foodName: string;
  mealName: string;
  unitName: string;
  buttonText: string;
  itemQuantity: string;
  isUpdating: boolean;
  updateItemQuantity: (quantity: string) => void;
  onPressAddOrUpdateBtn: () => void;
}

const AddDiet: React.FC<Props> = ({
  foodName,
  mealName,
  unitName,
  buttonText,
  itemQuantity,
  isUpdating,
  updateItemQuantity,
  onPressAddOrUpdateBtn,
}) => {
  const handleQunatityChange = (event: any) => {
    if (event.target.value === ".") {
      updateItemQuantity("0.");
    } else if (/^\d*\.?\d?$/.test(event.target.value)) {
      updateItemQuantity(event.target.value);
    }
  };

  return (
    <div className="absolute bottom-0 left-0 right-0 bg-white rounded-t-xl shadow-xl">
      <div className="p-4 text-base font-semibold border-b border-gray-300">
        <p>
          Add <span className="capitalize">{foodName}</span> as{" "}
          <span className="capitalize">{mealName}</span>
        </p>
      </div>
      <div className="p-4 flex items-center gap-4">
        <Input
          name="quantity"
          type="number"
          className="w-full"
          value={itemQuantity}
          onChange={handleQunatityChange}
          placeholder="Enter Quantity"
        />
        <Input
          name="unit"
          className="w-full"
          value={unitName}
          onChange={handleQunatityChange}
          disabled
        />
      </div>
      <div className="pt-0 p-4">
        <Button
          label={buttonText}
          className="w-full"
          onClick={onPressAddOrUpdateBtn}
          loading={isUpdating}
          disabled={isUpdating || Number(itemQuantity || 0) === 0}
        />
      </div>
    </div>
  );
};

export default AddDiet;
