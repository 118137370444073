import React, { ReactNode } from "react";
import DynamicSVG from "../DynamicSVG";
import { THEME_COLORS } from "src/theme/colors";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import Text from "../Text";

interface Tab {
  label: string;
  key: string;
  icon?: ReactNode;
}

interface TabSwitcherProps {
  tabs: Tab[];
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const TabSwitcherV2: React.FC<TabSwitcherProps> = ({
  tabs,
  activeTab,
  setActiveTab,
}) => {
  const { clientSource } = useSelector((state: RootState) => state.auth);
  return (
    <ul className="flex justify-between pt-5 shadow-lg">
      {tabs.map((tab) => (
        <li
          key={tab.key}
          className="flex flex-col flex-1 items-center cursor-pointer"
          onClick={() => setActiveTab(tab.key)}
        >
          <div
            className={`text-base flex px-4 py-1 rounded-2xl items-center justify-center gap-1 ${
              activeTab === tab.key
                ? "text-text-green bg-bg-green font-extrabold"
                : "text-text-subtitle font-semibold"
            }`}
          >
            {activeTab === tab.key && tab.icon && (
              // <DynamicSVG size="w-5 h-5" color="#1D4D38">
              <DynamicSVG
                size="w-5 h-5"
                color={THEME_COLORS[clientSource]["--color-tab-dark-green"]}
              >
                {tab.icon}
              </DynamicSVG>
            )}
            <Text
              variant="body-large"
              className={activeTab !== tab.key ? "font-normal" : ""}
            >
              {tab.label}
            </Text>
          </div>
          {activeTab === tab.key && (
            <div className="h-1 w-12 mt-1 rounded-tl-full rounded-tr-full bg-button-green-primary" />
          )}
        </li>
      ))}
    </ul>
  );
};

export default TabSwitcherV2;
