import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { common, appointment } from "src/types/index";

const initialState: appointment.AppointmentStateType = {
  appointmentList: {
    isLoading: false,
    data: [],
    error: "",
    isLastPageReached: false,
  },
  appointmentTimeSlot: {
    isLoading: false,
    data: [],
    error: "",
  },
};
const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    getAppointmentListRequestAction: (
      state: appointment.AppointmentStateType,
      _action: PayloadAction<any>
    ) => {
      state.appointmentList.isLoading = true;
      state.appointmentList.error = "";
      if (
        Number(_action.payload.payload.page) === 1 &&
        state.appointmentList.isLastPageReached
      ) {
        state.appointmentList.isLastPageReached = false;
      }
    },
    getAppointmentListSuccessAction: (
      state: appointment.AppointmentStateType,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.appointmentList.isLoading = false;
      if (Number(action.payload.code) === 1) {
        state.appointmentList.isLastPageReached =
          action.payload.data.appointment_list.length < 5;
        if (Number(action.payload?.page) === 1) {
          state.appointmentList.data = [
            ...action.payload.data.appointment_list,
          ];
        } else if (Number(action.payload?.page) > 1) {
          state.appointmentList.data = [
            ...state.appointmentList.data,
            ...action.payload.data.appointment_list,
          ];
        }
      } else {
        state.appointmentList.isLastPageReached = true;
      }
      state.appointmentList.error = "";
    },
    getAppointmentListErrorAction: (
      state: appointment.AppointmentStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.appointmentList.isLoading = false;
      state.appointmentList.data = [];
      state.appointmentList.error = error;
    },
    cancelAppointmentRequest: (
      state: appointment.AppointmentStateType,
      _action: PayloadAction<any>
    ) => {
      state.appointmentList.isLoading = true;
      state.appointmentList.error = "";
    },
    cancelAppointmentSuccess: (
      state: appointment.AppointmentStateType,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.appointmentList.isLoading = false;
      state.appointmentList.error = "";
    },
    cancelAppointmentError: (
      state: appointment.AppointmentStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.appointmentList.isLoading = false;
      state.appointmentList.error = error;
    },
    getTimeSlotRequestAction: (
      state: appointment.AppointmentStateType,
      _action: PayloadAction<any>
    ) => {
      state.appointmentTimeSlot.isLoading = true;
      state.appointmentTimeSlot.error = "";
      state.appointmentTimeSlot.data = [];
    },
    getTimeSlotSuccessAction: (
      state: appointment.AppointmentStateType,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.appointmentTimeSlot.isLoading = false;
      if (action.payload.code === "1") {
        state.appointmentTimeSlot.data =
          action.payload.data.result?.time_slot ?? [];
      }
    },
    getTimeSlotErrorAction: (
      state: appointment.AppointmentStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.appointmentTimeSlot.isLoading = false;
      state.appointmentTimeSlot.error = error;
    },
    addAppointmentRequestAction: (
      state: appointment.AppointmentStateType,
      _action: PayloadAction<any>
    ) => {
      state.appointmentTimeSlot.isLoading = true;
      state.appointmentTimeSlot.error = "";
    },
    addAppointmentSuccessAction: (
      state: appointment.AppointmentStateType,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.appointmentTimeSlot.isLoading = false;
    },
    addAppointmentErrorAction: (
      state: appointment.AppointmentStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.appointmentTimeSlot.isLoading = false;
      state.appointmentTimeSlot.error = error;
    },
  },
});

export const {
  getAppointmentListRequestAction,
  getAppointmentListSuccessAction,
  getAppointmentListErrorAction,

  cancelAppointmentRequest,
  cancelAppointmentSuccess,
  cancelAppointmentError,

  getTimeSlotRequestAction,
  getTimeSlotSuccessAction,
  getTimeSlotErrorAction,

  addAppointmentRequestAction,
  addAppointmentSuccessAction,
  addAppointmentErrorAction,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
