import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { RootState } from "src/store";
import {
  authenticateUserRequest,
  authenticateUserSuccess,
} from "src/store/slices/authSlice";
import SpinnerLoader from "../atoms/Loader/SpinnerLoader";
import { getToken } from "src/helpers/localStorage";
import { setClientSource } from "src/store/slices/authSlice";
import { setThemeColors } from "src/store/slices/themeSlice";
import { setThemeColorsFromURL } from "src/helpers/themeHelper";
import { LOCAL_STORAGE } from "src/constants/common";

interface AuthUserProps {
  component: React.ComponentType<any>;
  [key: string]: any; // Allow additional props
}

const AuthUser: React.FC<AuthUserProps> = ({
  component: Component,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [hasError, setHasError] = useState(false); // Track error state

  useEffect(() => {
    const token = getToken();

    if (
      location.pathname !== "/" &&
      location.pathname !== "/progress/progress-device-section" &&
      location.pathname !== "/progress/device-details" &&
      location.pathname !== "/progress/device-result-page" &&
      location.pathname !== "/progress/device-error"
    ) {
      // For routes other than "/", check token
      // TODO
      if (!token) {
        navigate("/", { replace: true });
        return;
      }
      const clientSource =
        localStorage.getItem(LOCAL_STORAGE.CLIENT_SOURCE) || "default";
      const userInfo = localStorage.getItem(LOCAL_STORAGE.USER_INFO);

      // Set clientSource and theme colors
      const themeColors = setThemeColorsFromURL(clientSource);

      dispatch(setClientSource(clientSource));
      dispatch(setThemeColors(themeColors));
      if (userInfo)
        dispatch(authenticateUserSuccess({ data: JSON.parse(userInfo) }));
      setIsLoading(false);
    } else {
      // For "/" route, always check for "data" param
      const queryParams = new URLSearchParams(location.search);
      const urlParams = Object.fromEntries(queryParams.entries());
      if (urlParams?.data) {
        setIsLoading(true); // Start loading
        try {
          const paramsData = JSON.parse(decodeURIComponent(urlParams?.data));
          const clientSource =
            paramsData.clientSource === "MyT"
              ? "default"
              : paramsData.clientSource || "default";
          localStorage.setItem(
            LOCAL_STORAGE.QUERY_PARAMS,
            JSON.stringify(paramsData)
          );
          localStorage.setItem(
            LOCAL_STORAGE.CLIENT_SOURCE,
            clientSource.toLocaleLowerCase()
          );

          // Set theme colors
          const themeColors = setThemeColorsFromURL(
            clientSource.toLocaleLowerCase()
          );
          dispatch(setThemeColors(themeColors));
          const payload = paramsData.uuid
            ? { uuid: paramsData.uuid }
            : { contact_no: paramsData.mobileNumber };

          // Regenerate the token
          authenticateUser(payload);
        } catch (error) {
          console.error("Error parsing URL parameters:", error);
          setHasError(true);
          setIsLoading(false);
        }
      } else if (!token) {
        // If no token and no "data", it's an error
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // If token exists but no "data", use stored clientSource
        const clientSource =
          localStorage.getItem(LOCAL_STORAGE.CLIENT_SOURCE) || "default";
        const userInfo = localStorage.getItem(LOCAL_STORAGE.USER_INFO);

        // Set clientSource and theme colors
        const themeColors = setThemeColorsFromURL(clientSource);

        dispatch(setClientSource(clientSource));
        dispatch(setThemeColors(themeColors));
        if (userInfo)
          dispatch(authenticateUserSuccess({ data: JSON.parse(userInfo) }));
        setIsLoading(false);
      }
    }
  }, [location.pathname, location.search, navigate]);

  const authenticateUser = (payload: any) => {
    new Promise((resolve, reject) => {
      dispatch(
        authenticateUserRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then(() => {
        setIsLoading(false); // API succeeded
      })
      .catch((error) => {
        console.error("Error authenticating user:", error);
        setHasError(true); // API failed
        setIsLoading(false);
      });
  };

  // Render loading state
  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <SpinnerLoader />
      </div>
    );
  }

  // Render error state
  if (hasError) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>Something went wrong. Please try again later.</p>
      </div>
    );
  }

  // Render the component when loading is complete and there is no error
  return <Component {...rest} />;
};

export default AuthUser;
