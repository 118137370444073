import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Button from "src/components/atoms/Button/Button";
import WeelPickerV2 from "src/components/atoms/WheelPickerV2";

import { RootState } from "src/store";
import {
  resetUpdateGoal,
  updateGoalRequest,
} from "src/store/slices/insightSlice";

interface Props {
  selectedGoal?: any;
  closeBottomSheet?: () => void;
}

const ExerciseGoalSelectionBottomSheet: React.FC<Props> = ({
  selectedGoal,
  closeBottomSheet = () => {},
}) => {
  const [goalValue, setGoalValue] = useState(selectedGoal);
  const {
    updatePatientExerciseGoal: { isLoading, data: resp, error },
  } = useSelector((state: RootState) => state.insight);
  const dispatch = useDispatch();

  const handleChange = (value: any) => {
    setGoalValue(value);
  };

  useEffect(() => {
    if (Number(resp?.code || 0) === 1) {
      // toast.success(resp?.message);

      closeBottomSheet();
    } else if (error) {
      toast.error("Unable to update patient's calorie goal.");
    }
    return () => {
      dispatch(resetUpdateGoal());
    };
  }, [resp, error]);

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (goalValue !== selectedGoal) {
      new Promise((resolve, reject) => {
        const payload = {
          goal_name: "exercise",
          goal_value: goalValue,
        };

        dispatch(updateGoalRequest({ payload, resolve, reject }));
      })
        .then((res: any) => {
          if (res?.code === "1") {
            closeBottomSheet();
            // toast.success("Exercise Goal Data is updated successfully!");
          } else {
            toast.error("Unable to update Exercise Goal Data!");
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Unable to update Exercise Goal Data!");
        });
    } else {
    }
  };

  return (
    <div>
      <div className="px-4 py-2 border-b border-b-gray-300 text-lg font-semibold">
        Exercise Goal
      </div>
      <div>
        <p className="text-sm text-gray-500 px-4 pt-4">
          Set a Goals based on how active you are, or how active you'd like to
          be each day
        </p>
        <section className="relative h-[35vh] flex items-center justify-center">
          <div className="flex items-center justify-center">
            <WeelPickerV2
              options={[
                { label: "10 min", value: "10" },
                { label: "20 min", value: "20" },
                { label: "30 min", value: "30" },
                { label: "40 min", value: "40" },
                { label: "50 min", value: "50" },
                { label: "60 min", value: "60" },
              ]}
              onChange={handleChange}
              value={goalValue}
            />
          </div>
          <div className="w-[40px] h-[40px] bg-primary absolute rotate-45 right-[-20px]" />
          <div className="w-[40px] h-[40px] bg-primary absolute rotate-45 left-[-20px]" />
        </section>
      </div>

      <div className="p-4">
        <Button
          label="Set Goal"
          onClick={handleSubmit}
          className="w-full"
          loading={isLoading}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default ExerciseGoalSelectionBottomSheet;
