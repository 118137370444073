import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Button from "src/components/atoms/Button/Button";
import WeelPickerV2 from "src/components/atoms/WheelPickerV2";

import { RootState } from "src/store";
import {
  resetUpdatePatientCaloriesGoalRequest,
  updatePatientCaloriesGoalRequest,
} from "src/store/slices/insightSlice";

interface Props {
  selectedGoal?: any;
  closeBottomSheet?: () => void;
}

const GoalSelectionBottomSheet: React.FC<Props> = ({
  selectedGoal,
  closeBottomSheet = () => {},
}) => {
  const [goalValue, setGoalValue] = useState(selectedGoal);
  const {
    updatePatientCaloriesGoal: { isLoading, data: resp, error },
  } = useSelector((state: RootState) => state.insight);
  const dispatch = useDispatch();

  const options = [
    {
      label: "1000",
      value: "1000",
    },
    {
      label: "2000",
      value: "2000",
    },
    {
      label: "3000",
      value: "3000",
    },
    {
      label: "4000",
      value: "4000",
    },
    {
      label: "5000",
      value: "5000",
    },
  ];

  const handleChange = (value: any) => {
    setGoalValue(value);
  };

  useEffect(() => {
    if (Number(resp?.code || 0) === 1) {
      // toast.success(resp?.message);

      closeBottomSheet();
    } else if (error) {
      toast.error("Unable to update patient's calorie goal.");
    }
    return () => {
      dispatch(resetUpdatePatientCaloriesGoalRequest());
    };
  }, [resp, error]);

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (goalValue !== selectedGoal) {
      new Promise((resolve, reject) => {
        const payload = {
          target_calories: Number(goalValue),
        };
        dispatch(
          updatePatientCaloriesGoalRequest({
            payload,
            resolve,
            reject,
          })
        );
      })
        .then((res) => {
          // if(res?.code === 1) {
          closeBottomSheet();
          // }
          //   setSelectedGoal(value.value);
          //   bottomSheetModalRef.current?.close();
          //   setBottomSheetType(null);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // setSelectedGoal(value.value);
      // bottomSheetModalRef.current?.close();
      // setBottomSheetType(null);
    }
  };

  return (
    <div>
      <div className="px-4 py-2 border-b border-b-gray-300 text-lg font-semibold">
        Calories Goal
      </div>
      <div>
        <p className="text-sm text-gray-500 px-4 pt-4">
          Define the Calories you must target to consume each day
        </p>
        <section className="relative h-[35vh] flex items-center justify-center">
          <div className="flex items-center justify-center">
            <WeelPickerV2
              options={options}
              onChange={handleChange}
              value={goalValue}
            />
          </div>
          <div className="w-[40px] h-[40px] bg-primary absolute rotate-45 right-[-20px]" />
          <div className="w-[40px] h-[40px] bg-primary absolute rotate-45 left-[-20px]" />
        </section>
      </div>

      <div className="p-4">
        <Button
          label="Set Goal"
          onClick={handleSubmit}
          className="w-full"
          loading={isLoading}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default GoalSelectionBottomSheet;
