import React, { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "src/components/atoms/Button/Button";
import Text from "src/components/atoms/Text";
import ProgressBackIcon from "src/components/atoms/ProgressBackIcon/ProgressBackIcon";
import timeoutError from "src/assets/animations/bca-device-error/bca_timeout_error.json";
import Lottie from "lottie-react";
import BcaWrongUser from "src/assets/images/devices/bca_wrong_user.png";
import BcaInvalidData from "src/assets/images/devices/bca_invalid_data.png";
import useQueryParams from "src/hooks/useQueryParams";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import sendMessageToParent from "src/helpers/parentMessageHandler";
import { calculateAge } from "src/helpers/common";

const DeviceErrorPage: FC = () => {
  const location = useLocation();
  const queryParams = useQueryParams(location.search);
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  let key = "TIMEOUT";
  if (queryParams && queryParams["eventData"]) {
    const parseData = JSON.parse(queryParams["eventData"]);
    key = parseData.status || "TIMEOUT";
  }

  const ERROR_DETAILS: any = {
    TIMEOUT: {
      header: (
        <Text
          variant="title-large"
          className="text-2xl font-medium text-text-primary mt-4"
        >
          Session timed out
        </Text>
      ),
      subHeader: (
        <Text
          variant="body-small"
          className="text-text-primary opacity-50 mb-2 mt- text-base leading-6"
        >
          It took too long for the data to sync with the MyTatva app. Step down
          from the scale and try again.
        </Text>
      ),
      content: (
        <Lottie
          data-testid="bca-device-timout-error-icon"
          animationData={timeoutError}
          loop
          style={{ width: 150, height: 150 }}
        />
      ),
      errorText: "Step down from the scale and try again",
    },
    WRONG_SCALE_DETECTED: {
      header: (
        <Text variant="title-medium" className="text-text-primary mt-4">
          This doesn't seem like you
        </Text>
      ),
      subHeader: (
        <Text
          variant="body-small"
          className="text-text-primary opacity-50 mb-2 mt- text-base leading-6"
        >
          It looks like someone else is trying to sync data to your account.
          Step down and try again.
        </Text>
      ),
      content: <img src={BcaWrongUser} />,
      errorText: "MyTatva can only sync data for one user",
    },
    INVALID_DATA: {
      header: (
        <Text
          variant="title-large"
          className="text-2xl font-medium text-text-primary mt-4"
        >
          Data Sync Required!
        </Text>
      ),
      subHeader: (
        <Text
          variant="body-small"
          className="text-text-primary opacity-50 mb-2 mt- text-base leading-6"
        >
          We encountered an issue while syncing your data. To ensure the
          accuracy and completeness of your health information, we need to
          re-sync with your Smart Scale.
        </Text>
      ),
      content: <img src={BcaInvalidData} />,
    },
  };

  const handleNavigate = () => {
    navigate("/progress/progress-device-section?active_tab=Devices");
  };

  const handleTryAgain = () => {
    if (user.data.dob && user.data.gender && user.data.height) {
      sendMessageToParent("syncDevice", {
        age: Number(calculateAge(user.data.dob, "YYYY/MM/DD")),
        userId: user.data.patientID,
        height: Number(user.data.height),
        gender: user.data.gender === "M" ? 1 : 2,
      });
    } else {
      navigate("/progress/progress-device-section?active_tab=Devices");
    }
  };
  return (
    <>
      <div className="border border-b border-[#E4E7EC]">
        <ProgressBackIcon
          onClick={() => {
            handleTryAgain();
          }}
        />
      </div>
      <div className="flex flex-col h-4/5">
        <div className="px-4 text-left">
          <div>{ERROR_DETAILS[key].header}</div>
          <div>{ERROR_DETAILS[key].subHeader}</div>
        </div>
        {ERROR_DETAILS[key] === "INVALID_DATA" && <img src="" />}
        <div className="flex flex-1 flex-col justify-center items-center gap-8">
          {ERROR_DETAILS[key]?.content}
          {ERROR_DETAILS[key]?.errorText && (
            <Text variant="title-small" className="opacity-70 font-regular">
              {ERROR_DETAILS[key]?.errorText}
            </Text>
          )}
        </div>
      </div>
      <div className="p-4 bg-white absolute bottom-0 w-full border-t-2">
        <Button
          label={"Retry Now"}
          onClick={() => {
            handleNavigate();
          }}
          loading={false}
          className="mb-2 h-12 w-full !rounded-xl"
        />
      </div>
    </>
  );
};

export default DeviceErrorPage;
