import { FC } from "react";

interface CancelAppointmentModalPropsType {
  message: string;
  onSuccessFunction: () => void;
  onCancelFunction: () => void;
}

const CancelAppointmentModal: FC<CancelAppointmentModalPropsType> = ({
  message,
  onSuccessFunction,
  onCancelFunction,
}) => {
  return (
    <div className="p-2">
      <div className="text-base">{message}</div>
      <div className="float-right mt-4 mr-3 w-[25%] flex items-center justify-between text-sm text-primary">
        <div onClick={onCancelFunction}>NO</div>
        <div onClick={onSuccessFunction}>YES</div>
      </div>
    </div>
  );
};

export default CancelAppointmentModal;
