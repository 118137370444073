import React, { ReactNode, ReactElement } from "react";

interface DynamicSVGProps {
  color?: string;
  size?: string;
  children: ReactNode;
}

const DynamicSVG: React.FC<DynamicSVGProps> = ({
  color = "",
  size = "w-8 h-8",
  children,
}) => {
  const clonedSvg = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(
        child as ReactElement<React.SVGProps<SVGSVGElement>>,
        {
          className: `${size} ${color} ${
            child.props.className || ""
          } transition-colors duration-300`,
          fill: color,
        }
      );
    }
    return child;
  });

  return <>{clonedSvg}</>;
};

export default DynamicSVG;
