import { methods, request } from "./base";

const commonApis = {
  getUserDetails: async (data?: any) =>
    await request({
      method: methods.POST,
      route: "/patient/get_patient_details",
      payload: data,
    }),

  authenticateUser: async (data?: any) =>
    await request({
      method: methods.POST,
      route: "/patient/login_or_register_patient",
      payload: data,
      priv: false,
    }),
  updateProfile: async (data?: any) =>
    await request({
      method: methods.POST,
      route: "/patient/update_profile",
      payload: data,
      priv: true,
    }),
};

export default commonApis;
