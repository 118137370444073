import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import AddCircleIcon from "../../../atoms/Icons/AddCircleIcon";

import { RootState } from "src/store";

interface Props {
  mealIcon: any;
  meal_type: string;
  start_time: string;
  end_time: string;
  onPressAdd?: () => void;
  addIconVisible?: boolean;
  total_calories: number;
  consumed_calories: number;
}

const CommonMealHeaderCard: React.FC<Props> = ({
  addIconVisible = true,
  mealIcon,
  meal_type,
  start_time,
  end_time,
  onPressAdd,
  consumed_calories,
  total_calories,
}) => {
  const theme = useSelector((state: RootState) => state.theme);
  const { dietPlan } = useSelector((state: RootState) => state.foodDiary);
  const { mode } = useSelector((state: RootState) => state.auth);

  return (
    <div className="p-4 border-b border-gray-200 flex items-center justify-between">
      <div className="flex items-center gap-4">
        <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center">
          <img src={mealIcon} className="" alt={meal_type} />
        </div>
        <div>
          <p className="text-sm font-semibold">{meal_type}</p>
          <p className="text-xs text-text-subtitle">
            {moment(start_time, "HH:mm:ss").format("h:mm A") +
              " - " +
              moment(end_time, "HH:mm:ss").format("h:mm A") +
              " | "}{" "}
            {(consumed_calories ?? 0) + " of " + (total_calories ?? 0) + " Cal"}
          </p>
        </div>
      </div>
      {mode !== "view_only" &&
        addIconVisible &&
        moment().isAfter(dietPlan.date) && (
          <button
            className="w-8 h-8 flex items-center justify-center"
            onClick={onPressAdd}
          >
            <AddCircleIcon color={theme.colors["--color-primary"]} />
          </button>
        )}
    </div>
  );
};

export default CommonMealHeaderCard;
