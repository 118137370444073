import React from "react";
import Text from "src/components/atoms/Text";
import BuyDevicesImage from "src/assets/images/devices/buyDevices.svg";
import ForwardArrowIcon from "src/assets/icons/common/froward-arrow-2.svg";

const BuyDevices = () => {
  return (
    <div className="gap-4 flex flex-col">
      <Text variant="headline-large">More GoodFlip Devices</Text>
      <div className="bg-[#F2F4F7] pl-4 pt-4 overflow-hidden rounded-xl w-[328px]">
        <Text variant="title-large">
          Master your body composition with smart scale
        </Text>
        <Text variant="body-medium" className="text-[#344054]">
          Smart scale delivers precise metrics including visceral fat, bone mass
          etc
        </Text>
        <div className="pt-4 gap-2 flex flex-col">
          <div className="bg-button-green-primary w-[131px] h-8 text-white flex items-center justify-center rounded-xl gap-2">
            <Text variant="label-medium">Buy Now</Text>
            <img src={ForwardArrowIcon} className="h-4 w-4" />
          </div>
          <Text variant="body-medium" className="pl-0.5 text-[#344054]">
            Already have a smart scale?{" "}
            <span className="underline"> Connect Now</span>
          </Text>
        </div>
        <div>
          <img src={BuyDevicesImage} className="h-auto w-full" />
        </div>
      </div>
    </div>
  );
};

export default BuyDevices;
