import React, { FC } from "react";
import InfoIcon from "src/components/atoms/Icons/InfoIcon";
import Text from "src/components/atoms/Text";

interface BCAVitalViewProps {
  parameter: any;
}
const BCAVitalView: FC<BCAVitalViewProps> = ({ parameter }) => {
  return (
    <div>
      <div className="bg-background-fourth w-8 h-8 rounded-full">
        <img src={parameter.icon} />
      </div>
      <div className="flex items-center" style={{ display: "ruby" }}>
        <span className="font-manrope text-base font-bold leading-5 text-text-primary mt-1">
          {parameter.name}
        </span>
        <span className="-mt-1 ml-1">
          <InfoIcon color="text-primary" width={12} height={12} />
        </span>
      </div>

      <div className="flex text-text-ternary mt-1">
        <Text variant="caption1-regular">Unit:</Text>
        <Text variant="caption1-regular" className="font-bold ml-1">
          {parameter.unit}
        </Text>
      </div>
    </div>
  );
};

export default BCAVitalView;
