import React, { FC, ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "src/store";
import sendMessageToParent from "src/helpers/parentMessageHandler";
import { calculateAge } from "src/helpers/common";

import Text from "src/components/atoms/Text";
import Modal from "src/components/atoms/Modal/Modal";
import SyncButton from "../SyncButton";

interface DeviceCardPropsType {
  CardImageComponent: ReactNode;
}

const DevicesCard: FC<DeviceCardPropsType> = ({ CardImageComponent }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientSource, user } = useSelector((state: RootState) => state.auth);
  const [openSyncModal, setOpenSyncModal] = useState<boolean>(false);
  const [selectDevice, setSelectDevice] = useState<"Tracky" | "Lifetrons">(
    "Tracky"
  );

  const handleSubmitDevice = async (device: any) => {
    try {
      setOpenSyncModal(false);
      if (user.data.dob && user.data.gender && user.data.height) {
        sendMessageToParent("syncDevice", {
          age: Number(calculateAge(user.data.dob, "YYYY/MM/DD")),
          userId: user.data.patientID,
          height: Number(user.data.height),
          gender: user.data.gender === "M" ? 1 : 2,
        });
      } else {
        navigate(`/progress/device-provide-details?device=${device}`);
      }
    } catch (err) {
      // error handling
    }
  };

  return (
    <div className="bg-[#FFFBEE] w-full rounded-2xl border border-background-light-yellow">
      <div className="flex px-4 pt-4 gap-3 items-center">
        <div className="bg-background-light-yellow rounded-lg relative h-[100px] w-[100px] overflow-hidden flex items-center justify-center">
          {CardImageComponent}
        </div>
        <div>
          <Text variant="title-small" className="text-text-primary">
            GoodFlip Smart Scale
          </Text>
          <Text variant="label-medium" className="text-text-secodary">
            Last logged: 24 Jul | 06:00 PM
          </Text>
        </div>
      </div>

      <div className="w-full  mt-7 flex items-center  rounded-2xl rounded-t-none">
        <div className="w-full rounded-b-xl bg-[#FEF1C7] h-10 pl-4 flex items-center relative">
          <Text
            variant="label-large"
            className="underline text-button-green-primary font-bold"
            onClick={() => {}}
          >
            View Logs
          </Text>
          <div className="absolute right-[10%] -top-8">
            <SyncButton
              onClick={() => {
                setOpenSyncModal(true);
              }}
            />
          </div>
        </div>
      </div>

      {openSyncModal && (
        <Modal
          onClose={() => {
            setOpenSyncModal(false);
          }}
          closeIcon={false}
          className="rounded-none !p-3"
        >
          <Modal onClose={() => setOpenSyncModal(false)} className="w-full">
            <div className="p-2 flex flex-col items-center">
              <div className="mt-2 w-[70%] ">
                <div className="w-full text-primary font-bold text-left">
                  Please select a device
                </div>
                <div>
                  <div
                    className="flex items-center rounded-[5px] bg-color-extra-light-gray w-[94%] p-2 mt-2"
                    onClick={() => setSelectDevice("Tracky")}
                  >
                    <div
                      className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                        selectDevice === "Tracky"
                          ? `border-primary bg-white`
                          : "border-gray-400"
                      }`}
                    >
                      {selectDevice === "Tracky" && (
                        <div className="w-2.5 h-2.5 rounded-full bg-primary"></div>
                      )}
                    </div>
                    <div className="text-left ml-2 text-gray-400">Tracky</div>
                  </div>
                  <div
                    className="flex items-center rounded-[5px] bg-color-extra-light-gray w-[94%] p-2 mt-2"
                    onClick={() => setSelectDevice("Lifetrons")}
                  >
                    <div
                      className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                        selectDevice === "Lifetrons"
                          ? `border-primary bg-white`
                          : "border-gray-300"
                      }`}
                    >
                      <div
                        className={`w-2.5 h-2.5 rounded-full  ${
                          selectDevice === "Lifetrons"
                            ? `bg-primary`
                            : "bg-gray-300"
                        }`}
                      ></div>
                    </div>
                    <div className="text-left font-[400] ml-2 text-gray-400">
                      Lifetrons
                    </div>
                  </div>
                  <div
                    className="w-[94%] bg-primary rounded-[5px] text-white p-2 text-center mt-2"
                    onClick={() => {
                      handleSubmitDevice(selectDevice);
                    }}
                  >
                    Submit
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </Modal>
      )}
    </div>
  );
};

export default DevicesCard;
