import { all, call, put, takeEvery } from "redux-saga/effects";
import * as actions from "../slices/appointmentSlice";
import { common } from "../../types";
import appointmentApis from "src/api/appointment";
import { constants } from "src/constants/common";

const GetAppointmentListSaga = function* getAppointmentListSaga({
  payload: { payload, resolve, reject },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      appointmentApis.getAppointmentListData,
      payload
    );

    if (response.code === "-1") {
      // throw new Error();
      // TODO: seesion expire
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }

    const tempResponse = {
      ...response,
      ...payload,
      page: payload.page_no,
    };

    yield put(actions.getAppointmentListSuccessAction(tempResponse));
    resolve(response);
  } catch (error) {
    yield put(
      actions.getAppointmentListErrorAction(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const GetTimeSlotRequestSaga = function* GetTimeSlotRequestSaga({
  payload: { payload, resolve, reject },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      appointmentApis.getTimeSlots,
      payload
    );
    if (response.code === "-1") {
      // throw new Error();
      // TODO: seesion expire
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }

    yield put(actions.getTimeSlotSuccessAction(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.getTimeSlotErrorAction(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};
const CancelAppointmentSaga = function* CancelAppointmentSaga({
  payload: { payload, resolve, reject },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      appointmentApis.cancelAppointment,
      payload
    );
    if (response.code === "-1") {
      // throw new Error();
      // TODO: seesion expire
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }

    yield put(actions.cancelAppointmentSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.cancelAppointmentError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const AddAppointmentSaga = function* AddAppointmentSaga({
  payload: { payload, resolve, reject },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      appointmentApis.addAppointment,
      payload
    );

    if (response.code === "-1") {
      // throw new Error();
      // TODO: seesion expire
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }

    yield put(actions.addAppointmentSuccessAction(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.addAppointmentErrorAction(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

export default function* appointmentSaga() {
  yield all([
    takeEvery(actions.getAppointmentListRequestAction, GetAppointmentListSaga),
    takeEvery(actions.cancelAppointmentRequest, CancelAppointmentSaga),
    takeEvery(actions.getTimeSlotRequestAction, GetTimeSlotRequestSaga),
    takeEvery(actions.addAppointmentRequestAction, AddAppointmentSaga),
  ]);
}
