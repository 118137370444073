import moment from "moment";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "src/store";
import Button from "src/components/atoms/Button/Button";
import ErrorText from "src/components/atoms/ErrorText";
import ProgressBackIcon from "src/components/atoms/ProgressBackIcon/ProgressBackIcon";
import Text from "src/components/atoms/Text";
import TextInput from "src/components/atoms/TextInput";
import { calculateAge, formatFeetAndInchesToString } from "src/helpers/common";
import sendMessageToParent from "src/helpers/parentMessageHandler";
import {
  setUserData,
  updatePatientDetailsRequest,
} from "src/store/slices/authSlice";

const DeviceProvideDetails: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.auth);
  const [showError, setShowError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    feet: "",
    inches: "",
    gender: user.data?.gender ?? "",
    dob: user.data?.dob
      ? moment(user.data?.dob, "YYYY-MM-DD").format("DD/MM/YYYY")
      : "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setShowError(false);
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (!isValidDateFormate(formData.dob)) {
      setShowError(true);
      return;
    }
    setIsLoading(true);
    const value = formatFeetAndInchesToString(formData.feet, formData.inches);

    const payload = {
      height: value,
      height_unit: "cm",
      updateHeightWeight: 1,
      gender: formData.gender,
      dob: moment(formData.dob, "DD/MM/YYYY").format("YYYY-MM-DD"),
    };

    new Promise((resolve, reject) => {
      dispatch(
        updatePatientDetailsRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then(() => {
        sendMessageToParent("syncDevice", {
          age: Number(calculateAge(payload.dob, "YYYY/MM/DD")),
          userId: user.data.patientID,
          height: Number(value),
          gender: formData.gender === "M" ? 1 : 2,
        });

        dispatch(
          setUserData({
            height: payload.height,
            dob: payload.dob,
            gender: payload.gender,
          })
        );
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const isValidDateFormate = (date: string) => {
    return (
      moment(date, "DD/MM/YYYY", true).isValid() &&
      !moment(date, "DD/MM/YYYY").isAfter(moment())
    );
  };
  return (
    <>
      <div className="h-screen bg-background-light-blue">
        <div className="border border-b border-[#E4E7EC]">
          <ProgressBackIcon
            onClick={() => {
              navigate("/progress/progress-device-section?active_tab=Devices");
            }}
          />
        </div>
        <div className="px-4 h-[calc(100dvh-139px)] overflow-scroll">
          <Text
            variant="title-large"
            className="text-2xl font-medium text-text-primary mt-4"
          >
            Enter your details
          </Text>
          <Text
            variant="body-small"
            className="text-text-primary opacity-80 mb-2 mt-2 text-base leading-6"
          >
            Enter the following details to continue
          </Text>
          <div className="flex flex-col gap-4">
            <div className="bg-white p-4 rounded-lg shadow-md">
              <Text variant="body-large">
                Enter your height<span className="text-label-high">*</span>
              </Text>
              <div className="flex gap-4">
                <TextInput
                  label="Feet"
                  placeholder="Enter Feet"
                  name="feet"
                  type="number"
                  onChange={handleChange}
                  value={formData.feet}
                />
                <TextInput
                  label="Inches"
                  placeholder="Enter Inches"
                  name="inches"
                  type="number"
                  onChange={handleChange}
                  value={formData.inches}
                />
              </div>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
              <Text variant="body-large">
                What is your gender?<span className="text-label-high">*</span>
              </Text>
              <div className="flex flex-col gap-3 mt-5">
                <div
                  className="flex items-center rounded-[5px] w-[94%]  mt-2"
                  onClick={() =>
                    setFormData((prev) => ({ ...prev, gender: "M" }))
                  }
                >
                  <div
                    className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                      formData.gender === "M"
                        ? `border-primary bg-white`
                        : "border-gray-400"
                    }`}
                  >
                    {formData.gender === "M" && (
                      <div className="w-2.5 h-2.5 rounded-full bg-primary"></div>
                    )}
                  </div>
                  <div className="text-left ml-2 text-text-primary">Male</div>
                </div>
                <div
                  className="flex items-center rounded-[5px] w-[94%]  mt-2"
                  onClick={() =>
                    setFormData((prev) => ({ ...prev, gender: "F" }))
                  }
                >
                  <div
                    className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                      formData.gender === "F"
                        ? `border-primary bg-white`
                        : "border-gray-400"
                    }`}
                  >
                    {formData.gender === "F" && (
                      <div className="w-2.5 h-2.5 rounded-full bg-primary"></div>
                    )}
                  </div>
                  <div className="text-left ml-2 text-text-primary">Female</div>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-md">
              <Text variant="body-large">
                Enter your date of birth
                <span className="text-label-high">*</span>
              </Text>
              <TextInput
                placeholder="Enter date of birth (dd/mm/yyyy)"
                name="dob"
                type="text"
                value={formData.dob}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex =
                    /^(0[1-9]|[12][0-9]|3[01])?(\/)?(0[1-9]|1[0-2])?(\/)?(\d{0,4})?$/;
                  if (regex.test(value)) {
                    handleChange({ target: { name: "dob", value } }); // Update state only if valid
                  }
                }}
              />
              {showError && !isValidDateFormate(formData.dob) && (
                <ErrorText message="Please enter valid date(dd/mm/yyyy)" />
              )}
            </div>
          </div>
        </div>
        <div className="p-4 bg-white absolute bottom-0 w-full border-t-2">
          <Button
            label="Submit Details"
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isLoading}
            className="mb-2 h-12 w-full !rounded-xl"
          />
        </div>
      </div>
    </>
  );
};

export default DeviceProvideDetails;
