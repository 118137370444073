import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { common, healthCoach } from "src/types/index";

const initialState: healthCoach.HealthCoachStateType = {
  healthCoachList: {
    isLoading: false,
    data: null,
    error: "",
  },
};

const healthCoachSlice = createSlice({
  name: "healthCoach",
  initialState,
  reducers: {
    getHealthCoachListRequest: (
      state: healthCoach.HealthCoachStateType,
      _action: PayloadAction<any>
    ) => {
      state.healthCoachList.isLoading = true;
      state.healthCoachList.error = "";
    },
    getHealthCoachListSuccess: (
      state: healthCoach.HealthCoachStateType,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.healthCoachList.isLoading = false;
      state.healthCoachList.error = "";
      state.healthCoachList.data = action.payload.data;
    },
    getHealthCoachListError: (
      state: healthCoach.HealthCoachStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.healthCoachList.isLoading = false;
      state.healthCoachList.error = error;
    },
  },
});

export const {
  getHealthCoachListRequest,
  getHealthCoachListSuccess,
  getHealthCoachListError,
} = healthCoachSlice.actions;

export default healthCoachSlice.reducer;
