import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

import useQueryParams from "src/hooks/useQueryParams";
import { addAppointmentRequestAction } from "src/store/slices/appointmentSlice";
import { bindQueryParams } from "src/helpers/common";

import Card from "src/components/atoms/Card/Card";
import { Header } from "src/components/molecules/Header";
import ClockIcon from "src/assets/icons/clock.svg";
import Button from "src/components/atoms/Button/Button";

import CalendarIcon from "src/assets/icons/calendarRound.svg";

const AppointmentConfirmationScreen: FC = () => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const onPressBack = () => {
    const { coach, health_coach_Id, selected_date } = queryParams;
    navigate(
      bindQueryParams("/appointment/book-slot", {
        coach,
        health_coach_Id,
        selected_date,
      })
    );
  };

  const getTitle = (coachType: string) => {
    return coachType === "nutritionist"
      ? "Nutritionist"
      : coachType === "physiotherapist"
      ? "Physiotherapist"
      : "Success Coach";
  };

  const onPressConfirm = () => {
    setLoading(true);
    const { health_coach_Id, selected_date, time_slot } = queryParams;
    const payload = {
      appointment_date: selected_date,
      consulation_type: "Video",
      health_coach_id: health_coach_Id,
      time_slot: time_slot,
      type: "Coach",
    };
    new Promise((resolve, reject) => {
      dispatch(
        addAppointmentRequestAction({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((res: any) => {
        toast.success(res?.message ?? "Appointment added successfully", {
          autoClose: 3000,
          theme: "colored",
        });
        navigate("/appointment/my-appointments");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="bg-background h-full relative">
      <Header
        backButton={true}
        rightButton={false}
        title={`Schedule Appointment`}
        onClickOfBackBtn={onPressBack}
      />
      <div className="p-4 ">
        <Card className="p-3">
          <div className="text-s font-bold">
            With {getTitle(queryParams["coach"] ?? "")}
          </div>
          <div className="my-4">
            <div className="flex">
              <div className="mr-2">
                <img src={CalendarIcon} className="h-5 w-5" />
              </div>
              <div className="text-sm">
                {moment(queryParams["selected_date"], "DD-MM-YYYY").format(
                  "dddd, DD MMMM, YYYY"
                )}
              </div>
            </div>
            <div className="flex mt-2">
              <div className="mr-2">
                <img src={ClockIcon} className="h-5 w-5" />
              </div>
              <div className="text-sm">
                {queryParams["title"]}, {queryParams["time_slot"]}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className=" p-4 bg-white absolute bottom-0 w-full">
        <Button
          label={!loading ? "Confirm" : ""}
          disabled={loading}
          onClick={onPressConfirm}
          loading={loading}
          className="mt-2 h-12 w-full !rounded-xl"
        />
      </div>
    </div>
  );
};

export default AppointmentConfirmationScreen;
