import { all, call, put, takeEvery } from "redux-saga/effects";
import * as actions from "../slices/healthCoachSlice";
import { common } from "../../types";
import healthCoachApis from "src/api/healthCoach";
import { constants } from "src/constants/common";

const getHealthCoachListSaga = function* getHealthCoachListSaga({
  payload: { payload, resolve, reject },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      healthCoachApis.getHealthCoachList,
      payload
    );

    if (response.code === "-1") {
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }

    yield put(actions.getHealthCoachListSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.getHealthCoachListError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

export default function* healthCoachSaga() {
  yield all([
    takeEvery(actions.getHealthCoachListRequest, getHealthCoachListSaga),
  ]);
}
