import React, { ReactElement } from "react";

import BackArrow from "src/assets/icons/common/back-arrow.svg";

interface HeaderProps {
  backButton?: boolean;
  title?: string;
  rightButton?: boolean;
  rightButtonComponent?: ReactElement;
  onClickOfRightBtn?: () => void;
  onClickOfBackBtn?: () => void;
  className?: string;
}

export const HeaderV2: React.FC<HeaderProps> = (props) => {
  const {
    backButton = true,
    title = "Title",
    rightButton = false,
    rightButtonComponent = <></>,
    onClickOfRightBtn = () => {},
    onClickOfBackBtn = () => {},
    className,
  } = props;

  return (
    <header
      className={`${className} p-4 flex justify-between items-center bg-white`}
    >
      <div className="flex gap-2.5 overflow-hidden items-center">
        {backButton && (
          <div onClick={onClickOfBackBtn} className="px-1 pr-2">
            <img
              src={BackArrow}
              alt="Back Arrow"
              className=" cursor-pointer w-3"
            />
          </div>
        )}
        <div className="overflow-hidden whitespace-nowrap text-ellipsis font-bold text-text-title text-lg">
          {title}
        </div>
      </div>
      <div onClick={onClickOfRightBtn}>
        {rightButton ? rightButtonComponent : ""}
      </div>
    </header>
  );
};
