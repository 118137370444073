import React from "react";
import { tv, type VariantProps } from "tailwind-variants";

// Define the typography variants
const typography = tv({
  base: "tracking-[0.25px]",
  variants: {
    variant: {
      "display-large": "font-playfair font-semibold text-13 leading-[60px] ",
      "display-medium":
        "font-playfair font-semibold text-[48px] leading-[52px]",
      "display-small":
        "font-playfair font-semibold text-[40px] leading-[44px] ",
      "headline-large": "font-playfair  text-[32px] leading-[40px] ",
      "headline-medium": "font-playfair text-[28px] leading-[36px]",
      "headline-small": "font-playfair  text-2xl ",
      "title-large": "font-roboto font-semibold text-[20px] leading-[26px]",
      "title-medium": "font-roboto font-semibold text-[18px] leading-[24px]",
      "title-small": "font-roboto font-semibold text-base",
      "body-large": "font-roboto  text-base ",
      "body-medium": "font-roboto text-sm ",
      "body-small": "font-roboto text-xs",
      "label-large": "font-roboto text-base",
      "label-medium": "font-roboto text-sm ",
      "label-small": "font-roboto text-xs",
      "body2-bold": "font-manrope text-sm font-bold",
      "caption1-regular": "font-manrope font-normal text-xs",
      "caption2-regular": "font-manrope font-normal text-[10px]",
      "header-h1": "font-manrope text-2xl",
      "header-h4": "font-manrope text-base font-bold leading-5",
      digital: "font-digital",
    },

    align: {
      left: "text-left",
      center: "text-center",
      right: "text-right",
    },
  },
  defaultVariants: {
    variant: "body-medium",
    align: "left",
  },
});

type TextProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof typography>;

// Text Component
const Text: React.FC<TextProps> = ({
  variant,
  align,
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={typography({ variant, align, class: className })}
      {...props}
    >
      {children}
    </div>
  );
};

export default Text;
