import classNames from "classnames";
import React, { FC } from "react";

interface ErrorTextPropsType {
  message: string;
  className?: string;
}
const ErrorText: FC<ErrorTextPropsType> = ({ message, className }) => {
  return (
    <div className={classNames("text-sm text-error", className)}>{message}</div>
  );
};

export default ErrorText;
