// src/store/index.ts
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";

import loaderReducer from "./slices/loaderSlice";
import themeReducer from "./slices/themeSlice";
import authSlice from "./slices/authSlice";
import foodDiarySlice from "./slices/foodDiarySlice";
import insightSlice from "./slices/insightSlice";
import landingSlice from "./slices/landingSlice";
import exerciseReducer from "./slices/exerciseSlice";
import progressSlice from "./slices/progressSlice";
import appointmentSlice from "./slices/appointmentSlice";
import healthCoachSlice from "./slices/healthCoachSlice";
import bcaSlice from "./slices/bcaSlice";

// Initialize saga middleware
const sagaMiddleware = createSagaMiddleware();

// Configure the Redux store with reducers and middleware
const store = configureStore({
  reducer: {
    loader: loaderReducer, // Add the loader reducer to the store
    theme: themeReducer, // Add the diet reducer to the store
    auth: authSlice,
    foodDiary: foodDiarySlice,
    insight: insightSlice,
    landing: landingSlice,
    exercise: exerciseReducer,
    progress: progressSlice,
    appointment: appointmentSlice,
    healthCoach: healthCoachSlice,
    bca: bcaSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false, // Disable redux-thunk, since we are using redux-saga
      immutableCheck: process.env.NODE_ENV !== "production", // Disable immutable checks in production
      serializableCheck: process.env.NODE_ENV !== "production", // Disable serializability checks in production
    }).concat(sagaMiddleware), // Add saga middleware
});

// Run the root saga
sagaMiddleware.run(rootSaga);

// Export types for use in other parts of the app
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
