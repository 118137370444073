import React from "react";
import Text from "src/components/atoms/Text";
import DevicesCard from "src/components/molecules/Devices/DeviceCard";
import ScaleImage from "src/assets/images/devices/scale.png";
import GlucoseImage from "src/assets/images/devices/glucose.png";
import BuyDevices from "../BuyDevices";

const Devices = () => {
  const getDeviceCard = (key: string) => {
    return key === "weigh" ? (
      <div className="bg-[#00000033] px-[3px] pt-1 w-23 rounded-lg absolute -bottom-1">
        <img src={ScaleImage} className="rounded-md" />
      </div>
    ) : key === "glucose" ? (
      <div className="h-full w-full">
        <img src={GlucoseImage} className="rounded-md" />
      </div>
    ) : (
      <></>
    );
  };
  return (
    <div>
      <Text variant="display-large">Connected Devices</Text>
      <div className="pt-4 gap-4 flex flex-col  pb-10">
        <div>
          <DevicesCard CardImageComponent={getDeviceCard("weigh")} />
        </div>
        <div>
          <DevicesCard CardImageComponent={getDeviceCard("glucose")} />
        </div>
      </div>
      <div className="pt-8">
        <BuyDevices />
      </div>
    </div>
  );
};

export default Devices;
