import { methods, request } from "./base";

const healthCoachApis = {
  getHealthCoachList: (payload?: object) => {
    const route = "/doctor/check_bcp_hc_details";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
};

export default healthCoachApis;
